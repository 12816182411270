var Sandro = Sandro || {};
var Common = Common || {};

Common.validator = Common.validator || {};

Sandro.header = Sandro.header || {};

Sandro.home = Sandro.home || {};

Sandro.product = Sandro.product || {};

Sandro.footer = Sandro.footer || {};

Sandro.minicart = Sandro.minicart || {};

Sandro.cart = Sandro.cart || {};

Sandro.checkout = Sandro.checkout || {};

Sandro.confirmation = Sandro.confirmation || {};

Sandro.account = Sandro.account || {};

Sandro.account.registration = Sandro.account.registration || {};

Sandro.search = Sandro.search || {};

Sandro.menudesk = Sandro.menudesk || {};

Sandro.errorpages = Sandro.errorpages || {};

Sandro.newspaper = Sandro.newspaper || {};

Sandro.splash = Sandro.splash || {};

Sandro.einstein = Sandro.einstein || {};

Sandro.contact = Sandro.contact || {};

Sandro.login = Sandro.login || {};

Sandro.errorpages.bindEvents = function() {

	function displayErrorBackground() {
		if($(window).width() < 767) {
			$(".errorpage-background").hide();
		} else {
			$(".errorpage-background").show();
		}
	}

	displayErrorBackground();

	$(window).resize(function() {
		displayErrorBackground();
	});
}

Sandro.search.bindEvents = function(){
	Sandro.header.loginAndRedirect();
	var loginform = 'form[name="simpleSearch"]',
		enableToggle = false, //set to true if the search input should be shown with toggle (as it was in the older version)
		isMobile = $(window).outerWidth() < 1024;

	// Will not query a search if the user doesn't type anything
	function disableSearch(searchInput, submitButton){

		var windowWidth = window.innerWidth;
		var searchLabel = $("#q").data("searchlabel");

		// Validation pressing Enter
		$(searchInput).on('keydown', function(e){
			if(e.keyCode === 13 && ($(searchInput).val() == searchLabel || $(searchInput).val() == "")){
				e.preventDefault();
			}
		});

		// Validation clicking on Search Icon
		$(submitButton).on('click', function(e){
			if(windowWidth < 1024 || $(window).scrollTop() >= 163){
				$(searchInput).toggleClass("showInput");
			}
			if($(searchInput).val() == '' || $(searchInput).val() == searchLabel){
				if (isMobile){
					$('.header-search').toggleClass('shown');
				}
				$(searchInput).val(searchLabel);
				e.preventDefault();
			}
		});


		$(document).on("focusout", function(event){
			event.stopPropagation();
			if($(searchInput).attr("value") == ""){
				$(searchInput).val(searchLabel);
			}
		});

		$(document).on('click', searchInput, function(){
			if($(searchInput).attr("value") == searchLabel){
				$(searchInput).val("");
			}
		});
	}

	if($('.customSelect').length){

		$(document).on('click', '.customSelect .refinement a', function(e){
			e.preventDefault();

			$(this).closest('ul').addClass('active');
			var optionID        = $(this).parent().data('value') ,
				selectReference = $(this).closest('.has-custom-select').find('select');

			if(!$(this).parent().hasClass('active')){
				selectReference.val(optionID);
				selectReference.trigger('change');
				$(this).closest('ul').addClass('active');
			}
		});

	}


	if($('.header-search').length){
		disableSearch('#q', '#btnSearch');
	}

	if(enableToggle || isMobile){

	/*	$('.header-search, loginform').bind('mouseenter mouseleave click focusout', function(e){
			var isHovered = $('.header-search:hover').length,
				isVisib = $(loginform).is(':visible'),
			 	focus = $(loginform).is(':focus');
	        if (e.type === 'mouseenter' || e.type === 'click') {
	        	$(loginform).css('display','block');
	        	$(loginform).focus();

	        }
	        if (e.type === 'click') {
	        	if($('#q').length > 0) {
	        		$('#q').focus();
	    		}
	        }
	        if (e.type === 'mouseleave' && isVisib === true){
	        	$(loginform).css('display','block');
	        }
	        if (focus === true){
	        	$(loginform).css('display','block');
	        }
//	        if (e.type === 'mouseleave' && isVisib === true && isHovered == 0){
//	           	$(loginform).fadeOut();
//	        }
	        $('.header-search, loginform').mouseleave(function(){
	        	if($('#q').length > 0) {
	        		var isFocus = $('#q').is(':focus');
	        	}
	        });

	    $('.header-search, loginform').bind('submit', function(e) {
	    	if($('#q').length > 0) {
	    		var searchLabel = $('#q').val();
	    		var searchValue = $('#q').attr("data-searchlabel")
	    	} else {
	    		var searchLabel = $('#qClone').val();
	    		var searchValue = $('#qClone').attr("data-searchlabel")
	    	}
	    	if(searchLabel === searchValue || searchLabel.length < 1 ) {
	    		e.preventDefault();
	    	}
	    });
	    });*/
	}

	$(document).on("mouseenter", "ul.menu-category a.level-1", function(){
		$("ul.menu-category a.level-1").addClass("unselect");
		$(this).removeClass("unselect");
	});

	$(document).on("mouseleave", "ul.menu-category", function(){
		$("ul.menu-category a.level-1").removeClass("unselect");
	});
	Sandro.product.eventLookComplet();
};

Sandro.search.highlightCategory = function() {
	$('div.menu-wrapper ul li a[href*="' + window.location.pathname + '"]')
		.find('.showOnHover').removeClass('showOnHover')
		.end().parent().addClass('active')
		.closest('div.level-2').closest('li').addClass('show');
};

Sandro.checkoutminicart = Sandro.checkoutminicart || {};

Sandro.home.bindEvents = function(){
	Sandro.home.initModalPopup();
};

Sandro.home.initModalPopup = function(){
	var delayDisplayPopup = $('#delayDisplayPopup').val() * 1000;
	var delayBeforeDisplayPopup =  $('#delayBeforeDisplayPopup').val() * 1000;
	var delayCookie = $('#delayCookie').val();
	var popupWrapper = $('.home-popup');
	var popupBox = $('.home-popup-box');
	popupBox.hide();
	var _this = this;

	if (popupBox.hasClass('popin-newsletter'))
		delayDisplayPopup = 24 * 3600 * 1000;

	this.init = function() {
		if (popupWrapper.length > 0 && !$.cookie('cookie-home-popup')) {
			popupWrapper
				.delay( delayBeforeDisplayPopup).fadeIn("slow", function() {
					$(window).resize();
					popupBox.show();
					$(document).on('click touchstart', '#popup-close-cta, .popup-bg, .close-popup', _this.close);
                })
				.delay( delayDisplayPopup ).fadeOut();

			$(window).bind('resize', _this.verticalAlign);
			$.cookie('cookie-home-popup', 'OK', {expires : Number(delayCookie)});
		}
	};

	this.verticalAlign = function() {
		var topPos = $(window).height() / 2 - popupBox.height() / 2;

		if (topPos < 0){
			topPos = 0;
		}

		popupBox.css({
			top: topPos
		})
	};

	this.close = function() {
		popupWrapper.stop().fadeOut();
		$('#popup-close-cta, .popup-bg').unbind('click', _this.close);
		$(window).unbind('resize', _this.verticalAlign);
	};

	this.init();
};

Sandro.product.mobileAddtoCart = function() {
	$(window).on('resize.pdp', function(){
		var container;
		if ( $(window).width() < 768 ) {
			container = $('.mobile-addtocart');
		} else {
			container = $('.desktop-addtocart');
		}

		if ($('form.pdpForm').length && !container.find('form.pdpForm').length) {
			container.html( $('form.pdpForm').remove() );
			app.product.initAddToCart();
		}

		if ( $(window).width() < 767 ){
			$('.sold-out-container > *').appendTo('.sold-out-container-mob');
		} else {
			$('.sold-out-container-mob > *').appendTo('.sold-out-container');
		}
	}).trigger('resize.pdp');
};

Sandro.product.fixedDetails = function() {
	var flagScroll = $('#product-content').height() + 158;
	var scrollContainer = $('body');

	scrollContainer.on('scroll.pdp', function() {
		var viewportTop = scrollContainer.scrollTop();
		$('.product-rows.clearfix')[viewportTop > flagScroll? 'addClass' : 'removeClass']('fixed-details');
	});

	$(document).on('click', '.fixed-details .soldoutDiv', function() {
		$('html, body').animate({ scrollTop: 0 }, '500');
	}).on('click', '.fixed-details li.attribute > .label', function(){
		$(this).parent().toggleClass('opened');
	}).on('fixedDetails', function() { // insert content for fixed details bar
		$('.product-variations .attribute li.selected').each(function() {
			var $this = $(this);
			$this.closest('.attribute').find('.fixed-value').html($this.html());
		});

		$('.pdp-fixed-info').append(
			$('.product-detail .product-name').clone(),
			$('.product-detail .product-promotion-percentage').clone()
		);
	}).trigger('fixedDetails');
};

Sandro.product.bindEvents = function(){
	// declarer la variable slide
	//Sandro.product.varSlide();
	// init of element who will move of Place
	//Sandro.product.moveElement();
	// init of slide for product
	Sandro.product.initSlide();
	//active Zoom of product
	//Sandro.product.zoomMobile();
	//switch of images
	Sandro.product.toggleImage();
	// we manage element of HTML on resize of the page
	//Sandro.product.resize();

	// Add to Wishlist Event
	Sandro.product.bindAddToWishlist();

	//Gestion de l'affichage de la popin d'envoi de mail a un ami
	Sandro.product.sendtofriendpopin();
	//unavailable popin
	Sandro.product.popinEmail();
	// resize guide des tailles
	Sandro.product.popinGuide();
	//
	Sandro.product.initSwitchSize();
	// verify type of mail
	Sandro.product.mail();

	$(document).on('click', '.add-to-cart-disabled', function(){
		Sandro.product.cannotaddtocart(this);
	});

	$(document).on('click tap', '.topPage a', function(e) {
		e.preventDefault();
		$('body, html').animate({scrollTop : 0});
		return false;
	});

	if($('.itemShare.pt').length){
		function loadScript(url, callback){

			var script = document.createElement("script");
			script.type = "text/javascript";

			if (script.readyState){  //IE
				script.onreadystatechange = function(){
					if (script.readyState == "loaded" ||
							script.readyState == "complete"){
						script.onreadystatechange = null;
						callback();
					}
				};
			} else {  //Others
				script.onload = function(){
					callback();
				};
			}

			script.src = url;
			//document.getElementsByTagName('head')[0].appendChild(script);
			$('head').append(script);
		}

		function configPinterest(mobile) {
			$(document).ready(function(){
				var linkPin = mobile ? $('.pinterest-link').attr('href') : $('.pinterest-link').data('script');
				loadScript(linkPin, function(){
					if(mobile) {
						$('.itemShare.pt a').attr('target', '_blank');
					} else {
			    	$('.itemShare.pt a').attr('onclick', "window.open('"+ linkPin +"','_blank', 'width=700, height=300');");
					}
				});
			});
		}


		if($(window).width() < 1023) {
			configPinterest(true);
		}
		else {
			configPinterest(false);
		}
	}

	if ($(window).width() < 767 && $('.footer-reassurance').length){
		if ($('.footer-reassurance').children().length == 0){
			$('.footer-reassurance').hide();
		}
	}

	$(document).on('click.selectsize', '.dropup-label', function(e){
		e.preventDefault();
		$(this).closest('.dropup-size').toggleClass('active');
	}).on('click.selectsize', '.dropup-size a[data-variationparameter]', function(e) {
		e.preventDefault();
		var selected = $(this).data('variationparameter'),
			target = $('.product-variations ul.swatches.size .swatchanchor').filter(function(){
				return $(this).data('variationparameter') === selected;
			});
		target.trigger('click');
	});

	Sandro.product.mobileAddtoCart();
	Sandro.product.fixedDetails();
};

/*
*	Sandro.product.eventLookComplet()
*	This function also handles add to cart event for Einstein Cart Impulse template
*/
Sandro.product.eventLookComplet = function() {

	//Display sizes inside a dropdown menu on Select Size" click
	$(document).off('click', '.completeLook a.btnSelectedSizes' ).on('click', '.completeLook a.btnSelectedSizes', function() {
		var btnSelectedSize = $(this).closest('div.complete-look-variations');
		if (btnSelectedSize.hasClass('active')) {
			btnSelectedSize.removeClass('active');
		} else {
			btnSelectedSize.addClass('active');
		}
	});

	//Handle Select Size Event
	$(document).on('click', 'form.form-look-complet ul li a.size', function() {
		var form = $(this).closest('form');
		var liParent = $(this).parent();
		// we get text of element selected
		var valSelected = $(this).text();

		// we define a variable to find the html and apply the value selected on it
		var valSelectedDefine = $(this).closest('.complete-look-variations').find('.valueSizeSelected');
		valSelectedDefine.text(valSelected);

		// Once the user has selected the size, we toggle the active class
		$(this).closest('div.complete-look-variations').addClass('selectedSize').removeClass('active');

		form.find('li.selected').removeClass('selected');
		form.find('input.pid').val("");

		if(!liParent.hasClass('size-unvailable')) {
			liParent.addClass('selected');
			form.find('input.pid').val(liParent.data('productid'));
			form.find('span.error-select-size').remove();
			form.find('button').removeClass('disabled')
		}
		else {
			form.find('button').addClass('disabled');
		}
	});

	/*
		Submit button adds product to cart
		1- Make sure there is a Product ID (input.pid) in Form's hidden input
		2- Add to Cart via AJAX when submit comes from pageContext = product || search
		3- When coming from "cart" pageContext, the submit button will continue with Form Action
	*/
	$(document).on('submit', '.completeLook form', function(e) {
		//Make sure there is a PID in Form's hidden input
		if(!$(this).find('button').hasClass('disabled') && $(this).find('input.pid').val() !== "") {
			//Add to Cart via AJAX when submit comes from pageContext = product || search
			if ( app.page.ns == 'product' || app.page.ns == 'search' ) {
				e.preventDefault();
				var form = $(this).closest("form");
				var data = form.serialize();

				app.cart.update(data, function (response) {
					//Display newly added product in mini cart if user on product page
					app.minicart.show(response);
					Sandro.minicart.bindEvents();
					$('#mobileBasket span').html($('#mini-cart .mini-cart-nb').html());
					$('.footerMobile .mini-cart-total span.mini-cart-nb').html($('#mini-cart .mini-cart-nb').html());
				});
			}
		}
		else {
			e.preventDefault();
			$(this).find('.error-select-size').remove();
			$(this).find('button').after('<span class="error-select-size" style="display:block;color:red">'+ $(this).find('button').data('error') +'</span>');
		}
	});
	/*
		Check if there's a price reduction / promotion
		to align all "Add to Cart" Buttons
	*/
	(function lookCompletPriceReduction() {
		if ( $('.lookBlock a.percentSeen').length ) {
			$('.completeLook').addClass("lookblock-price-promotion");
		}
	})();
}

Sandro.footer.bindEvents = function(){
	Sandro.footer.salutationRadio();
	Sandro.footer.shippingMethodRadio();
	Sandro.footer.changeCountry();
	Sandro.footer.newsletterInscription();
	Sandro.footer.initNewsletterPopin();
	Sandro.footer.loginSuccess();
	Sandro.footer.inputFilesContactus();
	Sandro.checkout.checkedRadio();
	Sandro.cart.taxesCalculation();
	Sandro.footer.toggleAccordion();
	Sandro.checkout.disableAutofill();
	Sandro.footer.animateFormLabel();
	Sandro.footer.animateFormLabelCheck();
};

Sandro.checkout.disableAutofill = function(){
	if (app.constants.CAPADRESSE_ENABLED) {
		$('form').attr('autocomplete','nope');
		$("form :input").each(function(){
			$(this).attr('autocomplete','nope');
		});
	}
}

Sandro.checkout.checkedRadio = function(){
	var radioAdressCont = $('.radioAdressCont'),
	 	inputRadio = 'input';
	setTimeout(function(){
		$('.radioAdressCont').each(function(){
			if($(this).find('input:checked').length){
				$(this).addClass('active');
			}
		});
	}, 1000);
	$(document).on('click','.radioAdressCont input', function(){
		that = $(this);
		that
		.closest('.radioAdressCont')
		.addClass('active')
		.siblings('.radioAdressCont')
		.removeClass('active');
	});
}

Sandro.cart.initQtyButtons = function(){
	$(".increment-button").click(function(){

    	var id = $(this).attr("pid");
    	var url = $(this).attr("href");
    	var quantity = $(this).data('quantity');

    	if(quantity != 'undefined' && quantity == 1) {
    		$('.' + id).click();
    		return;
    	}

    	$.ajax({
    		url:url,
    		cache:false
    	}).done(function(data) {
    		var res = data.split("§§§");
    		$('#mini-cart').html(res[0]);
    		if($('#cartNumberItems') && $('#cartNumberItems').html() != ''){
    			$('#cartNumberItems').html(res[1]);
    		}
    		if($('#cart-items-form') && $('#cart-items-form').html() != ''){
    			$('#cart-items-form').html(res[2]);
    		}
    		if($('.mini-cart-nb') && $('.mini-cart-nb').html() != ''){
    			$('.mini-cart-nb').html(res[3]);
    		}
    		if (res[4].trim() == 'false') {
    			$(".cart-action-checkout .button-fancy-large").attr("disabled", "disabled");
    			$('#cart-items-form').append(res[5]);
    		}else {
    			$(".cart-action-checkout .button-fancy-large").removeAttr("disabled");
    			$('.error-message.redlabel').remove();
    		}
    		Sandro.minicart.bindEvents();
    		Sandro.cart.bindEvents();
    	});
    	return false;
	});

}

Sandro.cart.taxesCalculation = function(){
	var input = '.estimation-tax-zipcode';

	if($(".shipping-form").length > 0){
		if($("#zipCodeEstimated").length > 0 && $.isNumeric($("#zipCodeEstimated").val())){
			$("input.zip").val($("#zipCodeEstimated").val());
		}
	}

	$(document).on('click', input, function(){
		if($(input).attr("placeholder") == $(input).attr("value")){
			$(input).val("");
		}
	});

	$(".estimation-tax-submit").unbind().on('click', function(e){
		e.preventDefault();
		var zipCodeValue = $(".estimation-tax-zipcode").val();
		Sandro.cart.refreshCartAmounts(zipCodeValue);
	});

	$("input[name='addressbook']").unbind().change(function(e){
		e.preventDefault();
		var zipcode = $("input[name='addressbook']:checked").data("zipcode");
		Sandro.cart.refreshCartAmounts(zipcode);
	});
}

Sandro.cart.initSwitchSize = function(){
	$("body").off("click", ".switchSize");
	$("body").on("click", ".switchSize", function(){
		Sandro.product.switchSize();
	});
}

Sandro.cart.refreshCartAmountsData = function(zipCodeValue) {
	var estimationShippingCostValue = $("#estimation-shipping-cost").val();
	var estimationOrderSubTotalValue = $("#estimation-order-subtotal").val();
	var estimationShippingCost = isNaN(estimationShippingCostValue) ? 0 : estimationShippingCostValue;
	var estimationOrderSubTotal = isNaN(estimationOrderSubTotalValue) ? 0 : estimationOrderSubTotalValue;

	return 'zipCode=' + zipCodeValue + '&estimationShippingCost=' + estimationShippingCost + '&estimationSubtotal=' + estimationOrderSubTotal + '&format=ajax';
}

Sandro.cart.refreshCartAmounts = function(zipCodeValue) {

	if ($("#estimation-tax-value").length == 0) return false;

	var correctZipCode = zipCodeValue ? zipCodeValue.toString().match(/^\d{5}([\-]?\d{4})?$/) : '';
	var isCartShowStep = $(".estimation-tax-zipcode").length > 0;
	var data = Sandro.cart.refreshCartAmountsData(zipCodeValue);

	$.ajax({
	    url: 	app.urls.refreshBasketAmounts,
	    data: 	data,
	    dataType: 'json',
	    success:
	        function(responseData){
	    	if(responseData.error || !correctZipCode) {
	    		var errorReason = app.resources.CHECKOUT_ESTIMATEDTAX_NOTESTIMATED;
	    		$("#estimation-tax-value").removeClass("error");

	    		/* If we are on other step than the cart-show step, we display a detailed error message */
	    		if(!isCartShowStep && responseData.errorReason.length > 0) {
	    			errorReason = responseData.errorReason;
    				$("#estimation-tax-value").addClass("error");
	    		}
	    		if($('.avataxValidation').length > 0 && $(".add-new-section").is(":visible")){
	    			$('.estimation-tax-error').show();
	    			$(".shipping-zip").addClass('avatax-error');
	    		}else{
	    			$("#estimation-tax-value").html(errorReason);
	    		}
	    	} else {
	    		if($('.avataxValidation').length > 0 && $(".add-new-section").is(":visible")){
	    			$(".shipping-zip").removeClass('avatax-error');
	    			$('.estimation-tax-error').hide();
		    	}else {
		    		$("#estimation-tax-value").removeClass("error");
		    		$("#estimation-tax-value").html(responseData.taxes);
		    	}
	    	}
	       $("#order-total-value").html(responseData.total);

	    },
	    error:
	    	function(responseData){
	    		$("#estimation-tax-value").html(app.resources.CHECKOUT_ESTIMATEDTAX_NOTESTIMATED);
	    	}
	});
	return false;
}


Sandro.footer.toggleAccordion =  function($targetContainer) {
    $(document).on('click', '.toggleMe', function(event) {
        event.preventDefault();
        event.stopPropagation();
        var parentClass = $(this).data('parentclass'),
            clickedItem = $('.'+parentClass).find('.open'),
            multitoggle = $(this).attr('data-multitoggle');
        if (multitoggle !== undefined) {
            $(this).toggleClass('open');
        } else {
            if ($(this)[0] === (clickedItem)[0]){
                $(this).toggleClass('open');
            }else{
                clickedItem.removeClass('open');
                $(this).toggleClass('open');
            }
        }

        //if this is the last item in the list-container, then scroll down the body
        if($(this).is('.'+parentClass+" span#toggleOpened")){
        	var body = $("html, body");
        	body.animate({scrollTop:$(document).height()}, 'toggle');
        }

        // If this is the creditcard block in checkout, update the radio buttons
        // Using custom event to make sure that this happens after the toggle.
        if($(this).is(".payment-form-header")){
        	$(this).trigger('updtRadioCreditCards');
        }
    });
}

Sandro.footer.animateFormLabel =  function() {
	$(document).on('focus', '.animate-label .input-text' , function(event){
		$(this).closest('.form-row').addClass('focus-in');
	});

	$(document).on('click', '.popin-trigger' , function(event){
		Sandro.footer.animateFormLabelCheck();
		app.checkout.refreshCache();
	});
	$(document).on('autocompleteclose', '.animate-label .input-text', function( event, ui ){
		Sandro.footer.animateFormLabelCheck();
	});
}

Sandro.footer.animateFormLabelCheck =  function() {
	$('form').find('.animate-label input:filled').blur().closest('.form-row').addClass('focus-in');
}

Sandro.footer.shippingMethodRadio = function(){
	var btn = '.radio-shippingmethod';
	$(document).on('click', btn, function(){
		// we substring(10) the id who begin with container- in way to abtain if of childrens
		var currentid = $(this).attr('id');
		var ischeck = $("#"+currentid.substring(10)).prop("checked");
		$(this).parent().children().removeClass("selected");
		if(ischeck){
			$(this).addClass("selected");
		}else{
			$(this).removeClass("selected");
		}
		if ($(window).width() < 768) {
			$(this).closest('fieldset').find('.toggleMe').removeClass('open');
		}
		Sandro.checkout.checkedRadio();
	});
}
Sandro.footer.salutationRadio = function() {
	var btn = '.radio-salutation';
	var input = 'div.salutation .input-radio';

	$(document).on('click',input, function(){
		fillHiddenSalutationInput(this);
	});

	function fillHiddenSalutationInput(that){
		$(that).closest('div.form-row').find('input.hidden').attr('value', $(that).val());
		$(that).valid();
		//$(that).closest('div.form-row').find('input.hidden').trigger('click');
	}

	// If an input was checked before the handlers were bound, make sure the input is filled in
	$(input + ':checked').each(function() {
		fillHiddenSalutationInput(this);
	});
};
Sandro.footer.loginSuccess = function(){
	if($(location).attr('href').indexOf('login=1') >= 0){
		dataLayer.push({
                'event': 'GAevent',
                'eventCategory': 'loginSuccess',
                'eventAction': 'OK'
		});

	}
}
Sandro.footer.changeCountry = function(){

	$('.changeCountry').on('click mouseover', function() {
		$('.changeCountryContent').toggle();
	});

	$('.changeCountry').on('mouseout', function() {
		$('.changeCountryContent').css('display','none');
	});

	$('.changeCountryContent a').on('click touchstart', function(e) {
		var el = $(this);
		var link = el.attr('href');
		window.location = link;
	});
}
Sandro.footer.inputFilesContactus = function() {
	var names = [];
	var filesMax = 4;

	//Les types de fichier autorisé dans l'input file
	var allowedFiles = ["pdf", "jpg", "jpeg", "gif", "png", "txt", "doc", "odt", "docx", "ppt", "pptx", "xls", "xlsx"];

	var submittable = true;

	function clearFiles() {
		$(".list-input-file li").remove();
		$(".wrapper-input-file div.error").hide();
		$(".delete-file").hide();
		$(".add-file").attr("style", "display:inline-block;"); // show() puts an inline display, and we need an inline-block

		names = [];
		submittable = true;
	}

	$(".delete-file").on("click", function(e) {
		e.preventDefault(); e.stopPropagation(); // Prevent input file label click
		clearFiles();
		$(".input-file").val('');
	});

	$(".input-file").on('drag dragstart dragend dragover dragenter dragleave drop', function(e) {
		e.preventDefault();
	    e.stopPropagation();
	}).on('drop change', function(e) {

		clearFiles();
		$(".delete-file").attr("style", "display:inline-block;"); // show() puts an inline display, and we need an inline-block
		$(".add-file").hide();

	    if (e.type == "drop"){
	    	droppedFiles = e.originalEvent.dataTransfer.files;
	    	//Check la compatibilité du drag and drop et dataTransfer
	    	if (droppedFiles === undefined){
	    		return;
	    	}
	    } else if (e.type == "change") {
	    	droppedFiles = $(this).get(0).files;
	    }

	    //Comportement OLD IE (8/9)
	    if (droppedFiles === undefined && $(this).val() != 0){
	    	droppedFiles = $(this).val();
			droppedFiles = droppedFiles.split("\\");

			$(".list-input-file").empty();
			$(".list-input-file").append("<li class='center'><span class='name-file'>" + droppedFiles[2] + "</span></li>");

			names.push(droppedFiles[2]);
			return;
	    }
	    //On arrete la fonction si c'est un OLD IE

	    var limit = names.length + droppedFiles.length;

	    if (limit > filesMax){
			$(".error-files").show();
			submittable = false;
		}

		var totalSize = 0;

	    $.each( droppedFiles, function(i, file) {

			var statusClass = "";

			var fileName = file.name.split('.');
			fileExt = fileName.pop().toLowerCase();
			fileName = fileName.join('.');

			if (allowedFiles.indexOf(fileExt) == -1) {
				$(".error-type-files").show();
				statusClass = " error";
				submittable = false;
			}

			totalSize += file.size;
			if(totalSize > 3000000) {
				$(".error-size-files").show();
				submittable = false;
			}

			if(fileName.length > 18){
				fileName = fileName.substring(0, 18) + "[...]." + fileExt;
			} else {
				fileName += "."+fileExt
			}
	    	$(".list-input-file").append("<li><span class='name-file" + statusClass + "' data-filename='" + file.name + "'>" + fileName + "</span></li>");

	    });
	});

	// To send form. If enctype value is "multipart/form-data", the form is no send
	$("#contactFormSend").on("submit", function(e) {

		e.preventDefault();

		var form = $(this);

		if(!submittable || !form.validate().form()) { return; }

		var formData = new FormData(this),
		btnName = $('#sendBtn').attr('name').toString(),
		btnValue =  $('#sendBtn').val().toString();

		formData.append(btnName,btnValue);
		var url = $("#contactFormSend").attr('action');

		var fileList = form.find("ul.list-input-file li");
		fileList.each (function() {
			var fileName = $(this).find(".name-file").data("filename");

			if (url.indexOf(fileName) === -1) {
				if (url.indexOf("?") >= 0) {
					url += "&files=" + fileName;
				}
				else {
					url += "?files=" + fileName;
				}
			}
		});

		$.ajax({
			url: url,
			type: 'POST',
			data: formData,
			success: function (data) {
				var $result = $(data).find('.confirmation-message');
				$('.form-row-button').html($result);
			},
			cache: false,
			contentType: false,
			processData: false
		});

	});
}
Sandro.product.varSlide = function(){
	var mySwiperLast;
};
Sandro.product.moveElement = function(){
	// on declare les elements qui vont bouger
	var classElement = {
			productName : '.pt_product-details .product-name[itemprop="name"]',
			productContainer : '.pt_product-details .product-image-container',
			productContent : '.pt_product-details #product-content',
			productContent1 : '.pt_product-details .product-col-1',
			productContent2 : '.pt_product-details .product-col-2',
			lastView : '.lastProductsSeenPush',
			btnLastView : '.lastProductsSeenPush h2'
	};

	// on recupere la width de la fenetre desktop et mobile
	var verifyWindows = {
			mobile : $(window).width() < 767,
			desktop : $(window).width() > 767
	}

	//on declare des variables qui nous permettent de savoir si l'element a un parent.
	var ifChildren = {
		existContainer : $(classElement.productContainer).children(classElement.productName),
		existContent : $(classElement.productContent).children(classElement.productName),
		existContentLast1 : $(classElement.productContent1).children(classElement.lastView),
		existContentLast2 : $(classElement.productContent2).children(classElement.lastView)
	};

	// on verifie si la width est destiné au mobile ou non.
	if(verifyWindows.mobile && ifChildren.existContainer.length === 0){
		$(classElement.productName).prependTo(classElement.productContainer);
	}else if(verifyWindows.desktop && ifChildren.existContent.length === 0 ){
		$(classElement.productName).insertBefore(classElement.productContent);
	}

	// we verify if div exist name lastProductsSeenPush
	if(verifyWindows.mobile && ifChildren.existContentLast2.length === 0){
		$(classElement.lastView).appendTo(classElement.productContent2);
	}else if(verifyWindows.desktop && ifChildren.existContentLast1.length === 0){
	//	$(classElement.lastView).appendTo(classElement.productContent1);
	}
	// event expand
	$(classElement.btnLastView).attr('data-expand', 'false');
	// verify if block is active or not
	if(verifyWindows.mobile){
		$(classElement.lastView).addClass('noActive');
	}else{
		$(classElement.lastView).removeClass('noActive');
	}
	// hide arrow
	if($(classElement.btnLastView).next('ul:hidden')){
		$(classElement.btnLastView).closest(classElement.lastView).find('.arrow-left, .arrow-right')
		.hide();
	}
		$(document).on('click', classElement.btnLastView, function(){
			if(verifyWindows.mobile && $(this).attr('data-expand') === 'false'){
				$(this).addClass('active');
				$(this).closest(classElement.lastView).addClass('swiper-lastPr');
				/*
				$(this).closest(classElement.lastView)
				.css('height', 'auto');
				*/
				$(this).next('ul')
				.slideDown(function(){

					// we init slide if element.lenght is than 3
					$(this).addClass('swiper-wrapper');
					$(this).find('li').addClass('swiper-slide');
					if($(classElement.btnLastView).next('ul').find('li').length > 3 ){
						$(classElement.lastView).removeClass('noActive');
						mySwiperLast = $('.swiper-lastPr').swiper({
						    slidesPerView: 3
						});
						//display:block arrow
						$(classElement.btnLastView).closest(classElement.lastView).find('.arrow-left, .arrow-right')
						.show();
					}

					var documentBody = (($.browser.chrome)||($.browser.safari)) ? document.body : document.documentElement;
					$(documentBody)
					.stop()
					.animate({
							scrollTop : $(classElement.lastView).offset().top
					},500);
					$(this).prev(classElement.btnLastView).attr('data-expand', 'true');
				});
			}else if(verifyWindows.mobile){
				$(this).removeClass('active');
				$(this).next('ul').slideUp(function(){
					$(this).prev(classElement.btnLastView).attr('data-expand', 'false');
					$(classElement.btnLastView).closest(classElement.lastView).find('.arrow-left, .arrow-right')
					.hide();
				});
			}else{
				return false;
			}
		});

		// move element
		$('.lastProductsSeenPush .arrow-left').on('click', function(e){
			e.preventDefault();
			mySwiperLast.swipePrev();
		});
		$('.lastProductsSeenPush .arrow-right').on('click', function(e){
			e.preventDefault();
			mySwiperLast.swipeNext();
		});
		// we verify if variable mySwiperLast exist and delete style and class of element last product view on desktop
		if(verifyWindows.desktop && typeof mySwiperLast !== 'undefined'){
			// we destroy event on slider
			$(document).off('click', classElement.btnLastView);
			if($('.staticView').length === 0){
				var newUl = $(document.createElement('ul')).addClass('staticView');
				$(classElement.lastView).append(newUl);

				// we delete style of swiper-slide
				$(classElement.lastView).find('.swiper-slide')
				.removeAttr('style');

				$(classElement.lastView).find('.swiper-slide').appendTo('.staticView');
				$(classElement.lastView).find('.swiper-wrapper').hide();

				$(classElement.lastView).removeAttr('style');
				//we delete event on width event off

			}
		}else{
			//$(document).on('click', classElement.btnLastView);
			$(classElement.lastView).find('.swiper-slide').appendTo('.lastProductsSeenPush .swiper-wrapper');
			$('.staticView').remove();
		}
};
// on cree une function globale lors du resize de le fenetre
Sandro.product.resize = function(){
	$(window).resize(function(){
		Sandro.product.varSlide();
		Sandro.product.moveElement();
	});
};

Sandro.product.switchSize = function(){
	$('.swatches.size, .switchSizeCont').toggleClass('frSizeShow');
};

Sandro.product.initSlide = function(){
	$(".product-main-container").each( function(idx, el) {

		var mainCtnr = $(this);

		if(mainCtnr.find('.productSlide').length > 0) return;

		var newDiv = $('<ul />');
		var newPaginate = $('<ul />');

		newDiv.addClass('productSlide swiper-wrapper').appendTo(mainCtnr.find(".productlargeimgdata"));
		newPaginate.addClass('paginateSlideMob').appendTo(mainCtnr.find(".productlargeimgdata"));

		var $imageTiles = $('<ul />').appendTo('.product-image-tiles');

		var data = new Array(),
			dataHi = new Array();
		if(mainCtnr.find(".productlargeimgdata").data('lgimg')!=null){
			data = mainCtnr.find(".productlargeimgdata").data('lgimg').split('|'),
			dataHi = mainCtnr.find(".productlargeimgdata").data('hiresimg').split('|');
		}

		var nbItem = data.length - 1;

		$.each(data, function(index) {

			var imgTitle = '';

			if(mainCtnr.length && mainCtnr.find(".productlargeimgdata").data('title')){
				imgTitle = mainCtnr.find(".productlargeimgdata").data('title');
			}

			if(dataHi[index].toLowerCase().indexOf("v_v.jpg") !== -1) {
				var newItemList = $('<li><a href="'+dataHi[index]+'" title="' + imgTitle + '" class="imgProduct videoLink" rel="gal1"><img src="'+ data[index] +'" /></a></li>');
				newItemList.addClass('slideImg swiper-slide').appendTo(newDiv);
			}else{
				if (dataHi[index].toLowerCase().indexOf(".mp4") !== -1) {
					var newItemList = $('<li><div class="play-button"></div><video muted playsinline><source src="'+ dataHi[index] +'#t=0.01" type="video/mp4"/></video></li>');
					newItemList.addClass('slideImg swiper-slide').appendTo(newDiv);
				} else {
					var newItemList = $('<li><a href="'+dataHi[index]+'" title="' + imgTitle + '" class="imgProduct" rel="gal1"><img src="'+ data[index] +'" /></a></li>');
					newItemList.addClass('slideImg swiper-slide').appendTo(newDiv);
				}
			}

			$imageTiles.append(newItemList.clone().removeClass()).find('a').removeClass().addClass('jqZoom');
		});

		$(document).on('click', '.product-image-tiles li video, .product-image-tiles li .play-button, .productlargeimgdata .slideImg video, .productlargeimgdata .slideImg .play-button', function(e) {
			var button = $(e.target).parent('li').find('.play-button');
			var video = $(e.target).parent('li').find('video');
			button.hide();
			$(video).trigger('play');
			$(video).on('ended', function (vEl) {
				vEl.target.currentTime = 0.01;
				button.show();
			})
		})

		if(mainCtnr.find('li').length >= 1) {
			setTimeout(function() {
				productSwiper();
			}, 500);
		}

		// SEO : Code allowing to add custom ALT informations
		if(mainCtnr.find('li').length >= 1){
			$(window).on('load', function(){
				var customSEOAlt = $("#customSEOalt");
				if(customSEOAlt.length > 0){
					mainCtnr.find('li div.zoomPad > img').attr('alt', customSEOAlt.val());
				}
			});
		}

		if ($(window).width() > 480 && !mainCtnr.find('.touch').length && !mainCtnr.find('.quickview').length) {
			setTimeout(function(){
				mainCtnr.find('.jqZoom').removeData('jqzoom');
				mainCtnr.find('.jqZoom').unbind();
				/* These are the elements that jqzoom created */
				mainCtnr.find('.zoomPad, .zoomPup').unbind();
				// mainCtnr.find('.slideImg img').each(function( index ) {
				// 	mainCtnr.find('.slideImg .imgProduct').eq(index).html($( this ).clone());
				// });
				mainCtnr.find('.jqZoom').jqzoom({
					zoomType: 'innerzoom',
					preloadImages: true,
					alwaysOn:false
				});
			}, 800);

		}

		$(document).on('mouseout','.productlargeimgdata .zoomWindow', function(){
			$('.productlargeimgdata .zoomPad').removeClass('active');
		});

		mainCtnr.find('.paginateSlideMob .itemPage').first().addClass('active');

		mainCtnr.find('.itemPage').on('click', function(){
			$(this).addClass('active').siblings().removeClass('active');
			$('.productSlide').stop().animate({
				'left' : - $('.slideImg').eq($(this).index()).position().left
			},'500');
		});

		function productSwiper(isQuickView){
			var mySwiper,
				swiperOptions = {};

			if(isQuickView === 'quickview'){
				Sandro.product.initSlide();
				swiperOptions = {
					mode:'horizontal',
					loop: true,
				    wrapperClass : 'product-thumbnails',
				    calculateHeight: true,
				    slideClass : 'thumb'
				}

				mySwiper = mainCtnr.find('.product-main-image-container .swiper-container').swiper(swiperOptions);

			} else{

				var indexMain;
				// var nbSlideThumb = 3;
				var lengthSwipe = $(".productSlide li").length;
				// var lenghtThumb = lengthSwipe - nbSlideThumb;
				var indexSwipe;
				// var indexThumb;

				// var mySwiperThumb = new Swiper('.product-thumbnails .swiper-container-thumb', {
				// 	mode:"vertical",
				// 	slidesPerView: "auto",
				// 	wrapperClass: 'swiper-wrapper',
				// 	slideClass: 'thumb',
				//   	slideElement: 'li',
				//   	spaceBetween: 20,
				//   	calculateHeight: true,
				//   	loop: false,
				//   	onInit: function() {
				//   		indexThumb = mySwiperThumb.activeIndex;
				//   	},
				//   	onSlideChangeEnd: function() {
				//   		indexThumb = mySwiperThumb.activeIndex;
				//   	}
				// });

				// if (mainCtnr.find(".swiper-container-thumb li").length <= 4) {
				// 	mainCtnr.find(".arrow-thumb-bottom, .arrow-thumb-top").hide();
				// }

				// mainCtnr.find(".arrow-thumb-top").on("click", function() {
				// 	mySwiperThumb.swipePrev();
				// });

				// mainCtnr.find(".arrow-thumb-bottom").on("click", function() {
				// 	mySwiperThumb.swipeNext();
				// });

				if(window.innerWidth < 768){
					modeSwiper = "horizontal";
				} else {
					modeSwiper = "vertical";
				}

				swiperOptions = {
					mode: 'horizontal',
					pagination: '.paginateSlideMob',
					paginationClickable: true,
					height:'auto',
					calculateHeight: true,
	        	    simulateTouch : false,
	        	    slidesPerView:1,
					loop: false,
					onInit : function(){
						if(mySwiper.slides.length == 3){
							mainCtnr.find('.product-main-image-container').addClass('singlePdt');
						}
						indexSwipe = mySwiper.activeIndex;
						$('.pt_product-details .arrowPr-right')[indexSwipe == (mySwiper.slides.length - 1) ? 'addClass' : 'removeClass']('disabled');
						$('.pt_product-details .arrowPr-left')[indexSwipe == 0 ? 'addClass' : 'removeClass']('disabled');
					},
					onSlideChangeEnd: function() {
						indexSwipe = mySwiper.activeIndex;
						$('.pt_product-details .arrowPr-right')[indexSwipe == (mySwiper.slides.length - 1) ? 'addClass' : 'removeClass']('disabled');
						$('.pt_product-details .arrowPr-left')[indexSwipe == 0 ? 'addClass' : 'removeClass']('disabled');
						showOrginalVideoImage();
					}
				}

				var slideTimeout;
				mySwiper = mainCtnr.find('.product-main-image-container .swiper-container').swiper(swiperOptions);


				// $(window).off('resize.pdp').on('resize.pdp', function(){
				// 	clearTimeout(slideTimeout);
				// 	slideTimeout = setTimeout(function(){
				// 		if ($(window).width() <= 768) {

				// 		} else {
				// 			console.log('============ destroy')
				// 			if (typeof(mySwiper) !== "undefined" ) {
				// 				mySwiper.destroy( true, true );
				// 				mySwiper = undefined;
				// 			}
				// 		}
				// 	}, 300);
				// }).trigger('resize.pdp');

				// mainCtnr.find(".productlargeimgdata.swiper-container").on("mouseenter", function() {
				// 	mainCtnr.find(".productlargeimgdata.swiper-container").bind('mousewheel DOMMouseScroll', function(event){
				// 		if (event.originalEvent.wheelDelta > 0 || event.originalEvent.detail < 0 || event.originalEvent.deltaY < 0) {
				// 			if(indexSwipe != 0){
				// 		    	event.preventDefault();
				// 		        mySwiper.swipePrev();
				// 			}
				// 	    }
				// 	    else {
				// 	    	if(indexSwipe < lengthSwipe){
				// 	    		event.preventDefault();
				// 		    	mySwiper.swipeNext();
				// 	    	}
				// 	    }
				// 	});
				// });

				// mainCtnr.find(".product-thumbnails").on("mouseenter", function() {
				// 	mainCtnr.find(".product-thumbnails").bind('mousewheel DOMMouseScroll', function(event){
				// 		if (event.originalEvent.wheelDelta > 0 || event.originalEvent.detail < 0 || event.originalEvent.deltaY < 0) {
				// 			if(indexThumb != 0){
				// 		    	event.preventDefault();
				// 		    	mySwiperThumb.swipePrev();
				// 			}
				// 	    }
				// 	    else {
				// 	    	if(indexThumb < lenghtThumb){
				// 	    		event.preventDefault();
				// 	    		mySwiperThumb.swipeNext();
				// 	    	}
				// 	    }
				// 	});
				// });

			}

			mainCtnr.find('.productSlide.swiper-wrapper').before(
				$('<a />', { class: 'arrowPr-left'}).on('click', function(){
					mySwiper.swipePrev();
				}),
				$('<a />', { class: 'arrowPr-right'}).on('click', function(e){
					mySwiper.swipeNext();
				})
			);
		}
	});

	function getPageScroll() {
		var pageScroll = new Object();
		if (self.pageYOffset) {
			pageScroll.y = self.pageYOffset;
			pageScroll.x = self.pageXOffset;
		} else if (document.documentElement && document.documentElement.scrollTop) {
			pageScroll.y = document.documentElement.scrollTop;
			pageScroll.x = document.documentElement.scrollLeft;
		} else if (document.body) {// all other Explorers
			pageScroll.y = document.body.scrollTop;
			pageScroll.x = document.body.scrollLeft;  
		}
		return pageScroll;
	};

	var isMobile = window.screen.width < 768 ? true : false;
	// Main imgae area
	var imgContainerPC = $('.product-main-container .product-image-tiles').find('li');
	var imgContainerMB = $('.product-main-container .swiper-get-arrows').find('.swiper-wrapper').find('.swiper-slide');
	var videoImageIndex = -1;
	var videoUrl= null;
	for (var index = 0; index < imgContainerPC.length; index++) {
		var videoUrl = imgContainerPC.eq(index).find('img').attr('src');
		if (videoUrl && videoUrl.lastIndexOf('_V.jpg') > 0){
			videoImageIndex = index;
		}
	}

	if (videoImageIndex == -1) {
		return;
	} else {
		if (isMobile) {
			var content = '<div id="product-video-dialog"></div>' + imgContainerMB.eq(videoImageIndex).find('a').html();
			imgContainerMB.eq(videoImageIndex).find('a').remove();
			imgContainerMB.eq(videoImageIndex).html(content);
			imgContainerMB.eq(videoImageIndex).addClass('video-images');
		} else {
			var content = '<div id="product-video-dialog"></div>' + imgContainerPC.eq(videoImageIndex).find('a').html();
			imgContainerPC.eq(videoImageIndex).find('a').remove();
			imgContainerPC.eq(videoImageIndex).html(content);
			imgContainerPC.eq(videoImageIndex).addClass('video-images');
		}
	}

	var videoPlayer = null;
	var lastClickDate = null;
	function isContinuousClicks(event){
		var date = new Date();
		date = date.getTime();
		if (lastClickDate !== null && (date - lastClickDate) < 1000 && $(this).find('video').length == 0) {
			return true;
		}
		lastClickDate = date;
		return false;
	}

	$('.video-images').on('click', function() {
		if (isContinuousClicks() == true) {
			return ;
		}
		if ($(this).find('video').length > 0) {
			if(videoPlayer.getState() == 'PLAYING'){
				videoPlayer.play(false);
				$('#product-video-dialog_display_button').css('opacity', '1');
			} else {
				videoPlayer.play(true);
			}
			return ;
		}

		var videoUrl = $(this).find('img').attr('src');
		videoUrl = videoUrl.split('?')[0];
		videoUrl = videoUrl.substring(videoUrl.indexOf('/on/demandware.static/')).replace('.jpg', '.mp4');
		var width = $(this).find('img').css("width");
		var height = $(this).find('img').css("height");

		videoPlayer = jwplayer('product-video-dialog').setup({
				file: videoUrl,
				width: Number(width.substring(0, width.length - 2)),
				height: Number(height.substring(0, height.length - 2)),
				autostart: true,
				mute: true,
				controls: false
			});

		videoPlayer.onPlaylistComplete(function() {
			showOrginalVideoImage();
		});

		$(this).find('img').css('display', 'none');
		$(this).find('img').css('opacity', '0');
		var eventID = setInterval(function() {
			if($('#product-video-dialog').length > 0 && $('#product-video-dialog_menu').length > 0) {
				clearInterval(eventID);
				$('#product-video-dialog_menu').remove();
			} else if($('#product-video-dialog').find('video').attr('src') == undefined) {
				$('#product-video-dialog').find('video').attr('src', videoUrl);
			}
		}, 500);

	});

	$('.video-images').on("touchstart", function() {
		if(videoPlayer){
			if ($(this).find('video').length > 0) {
				if(videoPlayer.getState() == 'PLAYING'){
					videoPlayer.play(false);
					$('#product-video-dialog_display_button').css('opacity', '1');
				} else {
					videoPlayer.play(true);
				}
				return ;
			}
		}
	});
	
	// Show original video image
	showOrginalVideoImage = function  () {
		$('#product-video-dialog').empty();
		$('#product-video-dialog').css('display', 'none');
		$('#product-video-dialog').css('opacity', '0');

		$('.video-images').find('img').css('display', 'block');
		$('.video-images').find('img').css('opacity', '1');
	}

	// Stop playing when not visible
	$('body').on('scroll', function() {
		var pageScroll = getPageScroll();
		var videoImagesElement= $('.video-images')[0];
		var videoElement= $('.video-images').find('video')[0];
		if (videoImagesElement !== undefined && videoElement !== undefined) {
			if ((pageScroll.y + window.screen.availHeight) > videoImagesElement.offsetTop != true
					||(videoImagesElement.offsetTop + videoImagesElement.offsetHeight) > pageScroll.y != true) {
				showOrginalVideoImage();
			}
		}
	});

};

Sandro.product.highlightCategory = function(){
	var tabPathName = window.location.pathname.split('/');

	// Removing the last two elements
	tabPathName.splice(tabPathName.length -2, 2);

	var pathName = tabPathName.join("/");

	$('div.menu-wrapper ul li a[href*="' + pathName + '"]')
		.find('.showOnHover').removeClass('showOnHover')
		.end().parent().addClass('active')
		.closest('div.level-2').closest('li').addClass('show');
}

Sandro.product.zoomMobile = function (){
	var viewImg = '.imgProduct';

	$(viewImg).each(function(){
		for (var i = 0; $(this).find('.buttonZoom').length === 0; i++){
			var buttonZoom = $(document.createElement('a')).addClass('buttonZoom').text('Zoom +');
			buttonZoom
			.css({
				'position' : 'absolute',
				'bottom': '15px',
				'right' : '9px',
				'text-align' :'center',
				'z-index': '100'
			})
			.appendTo($(this));
		}
	});

	if (typeof window.orientation != 'undefined') {
		$('.product-primary-image a.zoomMain').css({
			'display' : 'inline-block',
			'z-index': '100',
			'position' : 'absolute',
			'left' : '470px',
			'top' : '8px'
		});
	}

	$(document).on('click tap', '.buttonZoom', function(e){
		e.preventDefault();
		// on verifie si la div exist qu sein de la page
		for (var i = 0; $('.zoomBlock').length === 0; i++){
			var urlImg = $('.productlargeimgdata').data("hiresimg").split('|');
			var btnClose = $(document.createElement('a')).addClass('closeZoom').text(app.resources.CLOSE);
			var contentZoom = $(document.createElement('div')).addClass('zoomBlock');
			var indexImgZoom = $(this).closest('.slideImg').index();
			var createImg = $(document.createElement('img')).attr('src' , urlImg[indexImgZoom]);
			contentZoom
			.css('height' , $(document).height())
			.append(btnClose)
			.append(createImg)
			.appendTo($('body'))
			.show();
		}
		var documentBody = (($.browser.chrome)||($.browser.safari)) ? document.body : document.documentElement;
		$(documentBody).stop().animate({
			scrollTop : $('#wrapper').offset().top
		},'1000');

	});

	$(document).on('click tap touchstart', '.zoomMain', function(e){
		e.preventDefault();
		// on verifie si la div exist qu sein de la page
		for (var i = 0; $('.zoomBlock').length === 0; i++){
			var urlImg = $('.productlargeimgdata').data("hiresimg").split('|');
			var btnClose = $(document.createElement('a')).addClass('closeZoom').text(app.resources.CLOSE);
			var contentZoom = $(document.createElement('div')).addClass('zoomBlock');
			var indexImgZoom = $(this).closest('.product-image-container').find('.thumb.selected').index();
			var createImg = $(document.createElement('img')).attr('src' , urlImg[indexImgZoom]);

			contentZoom
			.css('height' , $(document).height())
			.append(btnClose)
			.append(createImg)
			.appendTo($('body'))
			.show();
		}
		var documentBody = (($.browser.chrome)||($.browser.safari)) ? document.body : document.documentElement;
		$(documentBody).stop().animate({
			scrollTop : $('#wrapper').offset().top
		},'1000');
	});

	$(document).on('click','.closeZoom', function(e){
		e.preventDefault();
		$(this).closest('.zoomBlock').remove();
	});
};

//toggleImage function should be called on window resize event
$(window).on("resize", function() {
	Sandro.product.toggleImage();
});

Sandro.product.toggleImage = function() {
    var imgsrc;
    var backsrc;
    var frontsrc;
    var imgProduct = $(".search-result-items li.grid-tile");
    if (imgProduct.length > 0) {
        imgProduct.each(function() {
            var current = 0;
            var el = $(this);
            var imgSRC = el.find("img");
            var product = $(this).data("product");
            if (product && product.length && product[0].imagesURL) {
                var imgURL = product[0].imagesURL.split(",");
                var imgURLlength = imgURL.length - 1;

                if (!el.hasClass("grid-tile-rupture")) {
                    el.find(".product-image").prepend("<span class='grid-tile-left'></span><span class='grid-tile-right'></span>");
                }

                el.find(".grid-tile-left").on('click', function() {
                    if (current != 0) {
                        current = current - 1;
                        if (imgURL[current].indexOf('.mp4') > -1) {
                            current = current - 1;
                        }
                        imgSRC.attr("src", imgURL[current]);
                    } else if (current == 0) {
                        current = imgURLlength;
                        imgSRC.attr("src", imgURL[current]);
                    }
                });
                el.find(".grid-tile-right").on('click', function() {
                    if (current != imgURLlength) {
                        current = current + 1;
                        if (imgURL[current].indexOf('.mp4') > -1) {
                            current = current + 1;
                        }
                        imgSRC.attr("src", imgURL[current]);
                    } else if (current == imgURLlength) {
                        current = 0;
                        imgSRC.attr("src", imgURL[current]);
                    }
                });
            }
        });
    }

	function toggleImageEnter(e) {
		imgsrc = $(e).find('img').attr('src');
		backsrc= $(e).find('img').data('backsrc');
		frontsrc= $(e).find('img').data('frontsrc');

		if(imgsrc != frontsrc){
			$(e).find('img').attr('src', frontsrc);
		}
		else if(imgsrc != backsrc){
			$(e).find('img').attr('src', backsrc);
		}
	}

	function toggleImageLeave(e) {
		imgsrc = $(e).find('img').attr('src');
		backsrc= $(e).find('img').data('backsrc');
		frontsrc= $(e).find('img').data('frontsrc');

		if(imgsrc != frontsrc){
			$(e).find('img').attr('src', frontsrc);
		}
		else if(imgsrc != backsrc){
			$(e).find('img').attr('src', backsrc);
		}
	}

	function toggleImageSwatch(e, tileElement, img, children) {
		var tile = $(e).closest(tileElement);
		var thumb = tile.find(img).filter(":first");
		var swatchImg = $(e).children(children).filter(":first");
		var data = swatchImg.data("thumb");
		var current = thumb.data('current');

		if(!current) {
		    thumb.data('current',{src:thumb[0].src, alt:thumb[0].alt, title:thumb[0].title});
		}

		thumb.attr({
			src : data.src,
			alt : data.alt,
			title : data.title
		});
	}

	$(document).on('mouseenter', '.product-tile .product-image .thumb-link, .product-tile .quick-actions, .thumb-link-no-swap-img',  function (e) {

		if($(this).hasClass('quick-actions')) {

			$(this).siblings('.product-image').find('.thumb-link').trigger(e.type);
		} else {
			$(this).closest('.product-tile').find('.quick-actions').css({'opacity' : '1'});
		}
        if(!$(this).hasClass("thumb-link-no-swap-img") && window.innerWidth > 768){
			toggleImageEnter(this);
        }

	});

	$(document).on('mouseleave', '.product-tile .product-image .thumb-link, .product-tile .quick-actions, .thumb-link-no-swap-img',  function (e) {
		e.preventDefault();
		$(this).closest('.product-tile').find('.quick-actions').css({'opacity' : '0'});
		if($(this).hasClass('quick-actions')) {
			$(this).siblings('.product-image').find('.thumb-link').trigger(e.type);
		}
		if(!$(this).hasClass("thumb-link-no-swap-img") && window.innerWidth > 768){
			toggleImageLeave(this);
		}

	});

	$(document).on("mouseenter", ".product-swatches .swatch-list a.swatch", function (e) {
		e.preventDefault();
		toggleImageSwatch(this, ".grid-tile", ".product-image a.thumb-link img", "img");
		var tile = $(this).closest(".grid-tile");
		var swatchImg = $(this).children("img").filter(":first");
		var colorName = swatchImg.data('colorname');
		if (colorName != null) {
			tile.find('.vignette').each(function() {
				if ($(this).hasClass(colorName)) {
					$(this).addClass('active');
				} else {
					$(this).removeClass('active');
				}
			});
		}
	});
	$(document).on("mouseleave", ".product-swatches .swatch-list a.swatch", function (e) {
		var tile = $(this).closest(".grid-tile");
		var swatchImg = $(this).children("img").filter(":first");
		var colorName = swatchImg.data('colorname');
		if (colorName != null) {
			tile.find('.vignette').each(function() {
				if ($(this).hasClass('default')) {
					$(this).addClass('active');
				} else {
					$(this).removeClass('active');
				}
			});
		}
	});
	$(document).on("mouseenter", ".itemSeen .swatchAll ul li a", function (e) {
		e.preventDefault();
		toggleImageSwatch(this, ".itemSeen.swiper-slide", "img", "span");
	});

	$(document).on("mouseleave", ".itemSeen .swatchAll ul li a", function (e) {
		e.preventDefault();

		var tile = $(this).closest(".itemSeen.swiper-slide");
		var thumb = tile.find("img").filter(":first");
		thumb.attr('src', thumb.data('backsrc'));
	});
};

Sandro.product.bindAddToWishlist = function() {
	$('.addToFavorite a').off('click').on('click', function(e){
		e.preventDefault();
		var $form = $(this).closest('.product-add-to-cart').find('.pdpForm');
		if( (!$(this).hasClass('btnFavorite')) ) {
			if($form.find('.add-to-cart-disabled').length > 0) {
				Sandro.product.cannotaddtocart(this);
				e.preventDefault();
				return;
			} else {
				return;
			}

		}

		var targetUrl = $(".addToFavorite a.btnFavorite").attr("href");

		var msgok = $(".addToFavorite a.btnFavorite").data("okmsg");
		if( typeof msgok == "undefined")
			msgok = "Terminé";

		var msgerror = $(".addToFavorite a.btnFavorite").data("errormsg");
		if( typeof msgerror == "undefined")
			msgerror = "Error";
		var productID = $(".addToFavorite a.btnFavorite").data("productid");
		var product = $(".addToFavorite a.btnFavorite").data("product");
		if( typeof $(".addToFavorite a.btnFavorite").attr("href") != "undefined" ) {
			$.ajax({
			 	url: targetUrl
			}).done(function(data) {
				var res = data.Result;
				if(res != null && res != 'none' && res != '') {
					$(".addToFavorite .favoriteEndMsg").empty().append(msgok);
					$(".addToFavorite .favoriteEndMsg").addClass("wishlist-added-success");
					$(document).trigger("add.to.wishlist", [product]);
					$('.mini-wishlist').trigger('updated.miniwishlist');
				} else {
					$(".addToFavorite .favoriteEndMsg").empty().append(msgerror);
					$(".addToFavorite .favoriteEndMsg").removeClass("wishlist-added-success");
				}
			}).fail(function() {
				$(".addToFavorite .favoriteEndMsg").empty().append(msgerror);
				$(".addToFavorite .favoriteEndMsg").removeClass("wishlist-added-success");
			});
		} else {
			$(".addToFavorite .favoriteEndMsg").empty().append(msgerror);
			$(".addToFavorite .favoriteEndMsg").removeClass("wishlist-added-success");
		}
	});

	// icon wishlist
	$('.btn-icon-wishlist').off('click').on('click', function(){
		//$('.addToFavorite a').trigger('click');
		$('.addToFavorite a')[0].click();
	})[ $('.addToFavorite a').length ? 'show': 'hide' ]();
};

Sandro.product.cannotaddtocart = function(that){
	var $form = $('.pdpForm');
	$form.find('span.error-select-attributes').remove();
	var errortext = $form.find('#add-to-cart').attr('title');
	$form.find('#add-to-cart').parent().prepend('<span class="error-select-attributes" style="display:block;color:red">'+errortext+'</span>');
};


Sandro.product.popinEmail = function(){
	$(document).on('click', 'button#soldout', function() {
		if($(this).hasClass('formEmail-disabled')){
			var errortext = $('#soldout').attr('title');
			if(!$('.soldoutDiv').find('.error-select-attributes').length > 0){
				$('.soldoutDiv').prepend('<span class="error-select-attributes" style="display:block;color:red">'+errortext+'</span>');
			}
		}
		else{
			$(this).css('display', 'none');
			$('.divAlert').css('display', 'block');
		}
	});
};


Sandro.product.mail = function (){
	var compteur = 0;
	var inputVm ='.alertEmail';
	var inputSubmit = '.btnAlert';
	var closePopin = '.closePopin';
	// nous supprimons le message d'erreur au focus
     $(document).on('focus click', '.alertEmail, .divAlert .toolError', function(){
    	 $('.divAlert .toolError').remove();
     });


     $(document).on('click', closePopin, function(){
     compteur = 0;
     });


	$(document).on('click', inputSubmit, function(e){
		e.stopPropagation();
		e.preventDefault();
    	var classToolError = '.toolError';
    	$(classToolError).remove();
		// on verifie si le format d'Email est correct, dans ce cas nous supprimons la tooltip Error si elle est présente.
    	var emailToAlert = $(this).closest('.formEmail').find('.alertEmail').val();
    	var pidToAlert = $(this).prev('.formEmail').find('input.pidToAlert').val();
    	if (emailToAlert.match("^([A-Za-z0-9\x27\x2f!#$%&*+=?^_'{|}~-]+(\.[A-Za-z0-9\x27\x2f!#$%&*+=?^_'{|}~-]+)*)@(([a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]|[a-zA-Z0-9]{1,63})(\.([a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]|[a-zA-Z0-9]{1,63}))*\.[a-zA-Z0-9]{2,63})$")  ) {
        	compteur = 0;
        	$(classToolError).remove();
        	var email = $('.alertEmail').val() || '';
		var url = $(this).data('href');
		url = url + '&email='+email;
		//alert(url);
		$.ajax({
			url : url,
			success : function(response) {
				if(response.success) {
					$('.formEmail').css('display', 'none');
					$('#soldoutMessage').css('display', 'none');
					$('.alert-result').css('display', 'block').find("span").html(response.message);
					$(document).trigger('replenishment.request');
				}
			},
			fail : function() {
				//alert('fail');
			}
		});


        }  else {

        	// nous créeons l'appel de la tooltip pour le Desktop, dans l'autre cas elle est destiné au mobile

			var label = $(this).closest('.formEmail').find('.alertEmail, .labelEmail');

			// check if the label exists

			if(label.length) {

				// check if the label has the class 'errorEmailActive'.
				// if it doesn't, create the div that will display the error message and set the textError.

				if (! label.hasClass('errorEmailActive')) {
					label.addClass('errorEmailActive');
					var toolError = $(document.createElement('div')).addClass('errorEmail');
		        	var textError = app.resources.INVALID_EMAIL;
		        	var middleHeightTool = $(this).closest('body').find(classToolError).outerHeight()/2;
					var middleHeightButton = $(this).prev('.alertEmail').outerHeight()/2;
					var pos = 10;
				}
			}

        	// nous créeons l'appel de la tooltip pour le Desktop, dans l'autre cas elle est destiné au mobile
			$(this).closest('.formEmail')
    		.append(toolError);

    		if($(compteur).length < 1){
        		compteur  = compteur +1 ;

        		$(this).closest('.formEmail')
        		.find(toolError)
        		.text(textError);
    		}
		}
});
}

Sandro.product.sendtofriendpopin = function(){
	 var el = {
			 contentSend : '.sendTo',
			 formContainer : '.formContainer',
			 contentImageFriend : '.productImageFriend',
			 imageFriend : 'img',
			 idSendToFriend  : '#send-to-friend-main',
			 classTitleProduct : '.send-to-friend-product',
			 h1 : 'h1'
	 };

	 function sendToFriend () {

	  var popinUrl= $('.sendToFriend').attr("data-href");
	  $.ajax({
	   url : popinUrl
	  })
	  .done(function (response) {
	   // success
	   //$('.sendTo').remove();
	   var contentresponse = $(response).find('#send-to-friend-form');

	   $("#send-to-friend-main").dialog({
	    width: ($(window).width() >= 767 ? 720 : 310),
	    dialogClass: "sendTo",
	    closeText: app.resources.CLOSE,
	    draggable: false,
	    close: function() {
	     $('.greyLayer, form#send-to-friend-form, .sendTo').remove();
	    }
	   });

	   $('body:not(:has(div.greyLayer))').append('<div class="greyLayer"></div>');
		$('.formContainer:not(:has(form#send-to-friend-form))').append(contentresponse);
		$('.sendTo').css({
				'top' : '80px',
				'position' : 'absolute'
		});



		  // move element of popin
			if($(el.contentSend).find(el.contentImageFriend).length === 0){
			  var divContentImage = $(document.createElement('div')).addClass('productImageFriend');
			  $(el.contentSend).find(el.formContainer).before(divContentImage);
			  $(el.contentSend).find(el.formContainer).first().find(el.imageFriend).appendTo(el.contentImageFriend);
			  $(el.contentSend).find('.dialog-required').insertBefore('.sendTo .send-button');
		  }
		  if($(window).width() < 767 &&  $(el.contentImageFriend).children(el.h1).length === 0){
			  $(el.contentSend).find(el.idSendToFriend).first().find(el.h1)
			  .clone()
			  .prependTo(el.contentImageFriend);
			  $(el.contentSend).find(el.classTitleProduct)
			  .clone()
			  .appendTo(el.contentImageFriend);
		  }
	    })
	    .fail(function () {

	  })
	  .always(function () {
	  });

	  return false;
	 }


	 $(document).on('click', '.sendToFriend', function(e){
	  $('body').animate({ scrollTop: 0 });
	  sendToFriend ();
	 });

	 $(document).on('click', '.required', function(e){
		 $(this).parent().removeClass('error');
		 $(this).find('span.error-message').remove();
	 })

	 $(document).on('click', '.send-button', function(e){

		 //remove error message
		 $('div.sendTo').find('.error-message').remove();
		 $('div.sendTo').find('.error').each(function(){
			 $(this).removeClass('error');
		 })

		 var globalvalid = true;
		 $('div.sendTo div.required').each(function(){
			 var inputvalid = true;
			 var input=$(this).find('input[type="text"]');

			 if(input.val().length <=0){
				 inputvalid = false;
				 if(!$(this).hasClass('error')){
				  $(this).addClass('error');
				  $(this).append('<span class="error-message" onclick="$(this).remove();">'+$(this).attr('data-required-text')+'</span>');
				 }
			 }
			 else if(input.hasClass('email')){
				 var emailvalue = input.val();
				 var regex={email: /^[\w.%+\-]+@[\w.\-]+\.[\w]{2,6}$/};
				 inputvalid = regex.email.test($.trim(emailvalue));
				 if(!inputvalid){
					 $(this).append('<span class="error-message" onclick="$(this).remove();">'+app.resources.EMAIL_ERROR+'</span>');
				 }
				 else{
					 if(input.hasClass('confirmfriendsemail')){
						var inputfriendemail =  $('div.sendTo').find('input.friendsemail');
						var friendemail = inputfriendemail.val()
						 if(emailvalue != friendemail){
							 inputvalid = false;
							 $(this).append('<span class="error-message" onclick="$(this).remove();">'+app.resources.EMAIL_CONFIRM_ERROR+'</span>');
						 }
					 }
				 }
		 	}


		  if(inputvalid && $(this).hasClass('error')){
			 $(this).removeClass('error');
			 $(this).find('.error-message').remove();
		  }

		  if(!inputvalid){
			  globalvalid=false;
		  }
	 });

	 if(globalvalid){
	 	e.preventDefault();
	 	var form = $(this).closest('form');
	 	var urlcall = form.attr('action');
	 	var formdata = form.serialize();
	 	$.ajax({
	 		url : urlcall,
	 		data : formdata + '&format=ajax',
	 		success : function(data){

	 			$(data).appendTo('body');
	 			$('form#send-to-friend-form, .sendTo').remove();
 			}

	 	});
 	}
	 else{return false;}


	 });




	 // window resize
	 $(window).resize(function(){
		 if($(window).width() > 767){
			 $(el.contentSend).width(720);
			 $(el.contentImageFriend).find(el.h1).remove();
		 }else if($(window).width() < 767 &&  $(el.contentImageFriend).children(el.h1).length === 0){
			 $(el.contentSend).width(320);
			 $(el.contentSend).find(el.idSendToFriend).first().find(el.h1)
			  .clone()
			  .prependTo(el.contentImageFriend);
			  $(el.contentSend).find(el.classTitleProduct)
			  .clone()
			  .appendTo(el.contentImageFriend);
		  }
	});
	 //close popin and other element
	 /*
	 $(document).on('click tap touchstart', '.btnClosefriend', function(e){
			e.preventDefault();
			$('#send-to-friend-main').hide();
			$('#send-to-friend-form').hide();
			$('.sendTo').hide();
			$('.friendLayer').remove();
		});
	*/
};

Sandro.product.initSwitchSize = function(){
	$("body").off("click", ".switchSize");
	$("body").on("click", ".switchSize", function(){
		Sandro.product.switchSize();
	});
}

Sandro.product.popinGuide = function(){
	el = {
		idGuide : '.sizeGrid'
	}
	$(window).resize(function(){
		 if($('#wrapper').width() < 960){
			$(el.idGuide).css({
				'width': '315px',
				'left' : '50%',
				'margin-left' : -($(el.idGuide).outerWidth() / 2)
			});
		 }else{
			 $(el.idGuide).css({
					'width': '500px',
					'left' : '50%',
					'margin-left' : -($(el.idGuide).outerWidth() / 2)
				});
		 }
	});

};
Sandro.lookbook = Sandro.lookbook || {};

Sandro.lookbook.bindEvents = function(){
	// declarer la variable slide
	Sandro.lookbook.actions();
	Sandro.lookbook.imageDelay();
};

Sandro.lookbook.actions = function(){
	$(window).load(function() {
	    $('.flexslider').flexslider({
	    	namespace: "lookbook-",
	    	animation: "slide",
	    	slideshow: false,
	    	controlNav: false,
	    	prevText: "",
	    	nextText: ""
	   	});
	  });
};

Sandro.lookbook.imageDelay = function() {
	if(!$('.oldie').length) {
		$('span[data-picture]').on("DOMSubtreeModified propertychange",function(){
			var isPictureFillDone = true;

			var imageContainer = $(this).closest('.shape');

			imageContainer.find('span[data-picture]').each(function() {
				if($(this).find('img').length && $(this).find('img').completed)  isPictureFillDone = false;
			});

			if(isPictureFillDone) {
				var lookContainer = imageContainer.closest('li');
				var imgToLoad = lookContainer.find('span[data-picture] img').size();
				var imgLoaded = 0;
				var isEverythingLoaded = false;
				lookContainer.find('span[data-picture] img').each(function(){
				    var img = new Image();
				    img.onload = function() {
				        imgLoaded += 1;
				        if(imgLoaded == imgToLoad) {
				        	lookContainer.find('.details').css('display', 'table-cell');
							$('footer').fadeIn();
						}
				    }
				    img.src = $(this).attr('src');
				});
			}

		});
	}
}

Sandro.checkout.bindEvents = function(){
	Sandro.checkout.inputsFocus();
	Sandro.checkout.customValidation();
	Sandro.checkout.initializeAddressForm();
	Sandro.checkout.bindSubmitForms();
	Sandro.checkout.billingAddressSelect();
	Sandro.checkout.shippingAddressSelect();
	Sandro.checkout.tooltipCode();
	Sandro.checkout.validatePickupStore();
	Sandro.checkout.sendNewsletterTag();
	Sandro.checkout.selectPickupStore();
	Sandro.checkout.showLastPickupStoreOnMap();
	Sandro.checkout.checkedRadio();
	Sandro.checkout.updateMapSize();
	Sandro.checkout.updateEventsCreditCard();
	Sandro.checkout.expandbasketOnMobile();
	Sandro.checkout.sendShippingMethodDatalayer();
	Sandro.checkout.maskCreditCard();

	if ($("[name$='_shippingMethodID']:checked").val() == "pointrelais") {
		Sandro.checkout.contribCrosslog();
	}
}

Sandro.checkout.maskCreditCard = function() {
	$(document).on('input', '.credit-card-number.mask-field', function(event) {
		$('[id$="paymentMethods_creditCard_number"]').val($(this).val().replace(/\s/g, ''));
		var maskNumber = $(this).val().replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim().substring(0,19);
		$(this).val(maskNumber);
	});

	$(document).on('focusout', '.credit-card-number.mask-field', function(event) {
		$('[id$="paymentMethods_creditCard_number"]').blur();
	});
}
Sandro.checkout.initializeAddressForm = function() {
	$(document).on("change IEchange", ".checkout-shipping select[id$='_country'], .addressEdit select[id$='_country']", function(){
		$(window).trigger('checkout.load.provinces',{country: this});
	});
	$(document).on("change IEchange", ".checkout-billing select[id$='_country'], .addressEdit select[id$='_country']", function(){
		$(window).trigger('checkout.load.billing.provinces',{country: this});
	});
}
/**
 * @function
 * @description Remove residual hash from the window URL to avoid unwanted behavior
 * on login/logout from shoprunner. This function must be called on popin form closing and
 * on ready state of the checkout
 */
Sandro.checkout.removeHashInURL = function() {
	history.pushState("", document.title, window.location.pathname + window.location.search);
	return;
}

Sandro.checkout.bindSubmitForms = function() {
	var $cache = {};
	//stock the shippingMethod
	$cache.shippingMethod = $("input[name$='_shippingMethodID']:checked").val();
	$cache.zipcode = $('#dwfrm_singleshipping_shippingAddress_addressFields_zip').val();
	var pickUpShippingMethods = ["777","pointrelais", "2SRUN"];
	/**
	 * @function
	 * @description Parse the inputs with the given name in order to force validator
	 * @param {String} inputName insert the name of the inputs you want to check
	 */
	function isFormPreValid(inputName){
		var formPreValid = true;
		$('#single-page-shipping-form select[name*="'+ inputName +'"], #single-page-shipping-form input[name*="'+ inputName +'"]').each(function() {
			if(!$(this).valid()) {
				formPreValid = false;
				/*
					For some reason, with several ajax calls, capSaisie disables the zipCode input.
					This code re-enables the zipcode input and reinserts the cached value of the zipCode
				*/
				if ( $(this).attr("id") === "dwfrm_singleshipping_shippingAddress_addressFields_zip" ) {
					$(this).prop("disabled", false);
					$(this).val($cache.zipcode);
					//Return the input's validated value
					formPreValid = $(this).valid();
				}
			}
			if (formPreValid == false) {
				$(this).removeClass('error-message');
				$(this).closest('form-row').removeClass('animate-label,focus-in error-container');
				$(this).siblings('.error-message').remove();
				return false;
			}
		});

		return formPreValid;

	}

	/**
	 * @function
	 * @description Verify that at least one input is filled before trying to validate the form
	 * @param {String} selector
	 */
	function submitSingleShippingForm(isFormPrevalid){
		if (isFormPrevalid) {
			$('#single-page-shipping-form').find('button').click();
		} else if ($('.selected-address').length) {
			$('.selected-address').removeClass('selected-address');
		}
	}
	$(document).ready(function() {
		//if shoprunner shipping method is available, remove redisual hash in URL
		if ($('div.sr_shippingOptionDiv').length > 0) {
			Sandro.checkout.removeHashInURL();
		}

		//checking if at least one input is filled before trying to validate the form
		var singleShippingFormValidated = isFormPreValid('_addressFields_');
		submitSingleShippingForm(singleShippingFormValidated);

		var isHideAlipay = $('#js-isHideAlipay');
		var isHideWechat = $('#js-isHideWechat');
		var isHideOnWechatBrowser = $('#js-isHideOnWechatBrowser');

		var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
		var isWeChat = /micromessenger/i.test(navigator.userAgent);
		if (isMobile && isHideWechat && isHideWechat.length !== 0) {
			$('#container-ccard-adyen_wechatpay').addClass('hidden');
		}
		if ((isMobile && isHideAlipay && isHideAlipay.length !== 0) 
			|| (isWeChat && isHideOnWechatBrowser && isHideOnWechatBrowser.length !== 0)) {
			$('#container-ccard-adyen_alipay').addClass('hidden');
		}
	});

	$(document).on('click', ".close-button", function(e) {
		//bind event on popin form closing that will remove once residual hash from the current url on hashchange
		if ($('div.sr_shippingOptionDiv').length > 0) {
			$(window).one('hashchange', function () {
				Sandro.checkout.removeHashInURL();
			});
		}

		if ($(this).closest('form').find('.radioAdressCont').length){
			$(this).closest('form').find('.address-fields').hide();
		}
	});

	$(document).on('click', "[name$='_shippingMethodID']", function(e) {

		app.progress.show("div.left_column");

		var shippingMethodID = $(this).val();

		var parametersToAppend = {
			shippingMethodID : shippingMethodID,
			format : 'ajax'
		};

		// attempt to set shipping method
		var url = $('#shipping-method-list').data('selectshippingmethodurl');
		url = app.util.appendParamsToUrl(url, parametersToAppend, true);

		 $.ajax({
			url: url,
			success: function (response) {
				if ($(response).find('#one-page-checkout').length > 0) {
					$('#main').html($(response).find('#main').html());

					app.ajax.load({
						url: app.urls.specificShippingRender,
						target: '.gmap-relay-container',
						callback: function(data) {

							if (app.constants.CAPADRESSE_ENABLED) {
								csInit();
							}

							if(shippingMethodID ==  "777") {
								setTimeout(function() {
									var settings = {};
									settings.storeUrl = app.urls.getPickupStores;
									settings.featureFilter = false;
									settings.locationSearchLabel = app.resources.FIND_A_STORE_NEAR;
									settings.locationSearchPlaceholder = app.resources.INDICATEPLACE;
									settings.storePickupMode = true;
									settings.geolocateButton = true;
									storeLocator.Store.infoPanelCache_ = {};
									Sandro.storelocator.initCore(settings);
									Sandro.checkout.selectPickupStore();
								}, 0)
								Sandro.checkout.updateMapSize();
								$('div#primary h1.hideindesktop-block').text('1/3 ' + app.resources.CHOOSE_A_STORE);
							} else if( shippingMethodID == "pointrelais" ) {
								$("h1.authenticaded-customer-select-address-title").fadeOut(0);
								Sandro.checkout.contribCrosslog();
							} else {
								$('div#primary h1.hideindesktop-block').text('1/3 ' + app.resources.SHIPPING_ADDRESS);
								/*
									When new shippingMethod is not pickup but previous is
									Make sure that all inputs for addressField have been checked by validator
									and then submit the button
								*/
								if (pickUpShippingMethods.indexOf($cache.shippingMethod) > -1 && !(pickUpShippingMethods.indexOf(shippingMethodID) > -1) ) {
									var singleShippingFormValidated = isFormPreValid('_addressFields_');
									submitSingleShippingForm(singleShippingFormValidated);
								}
							}
							//Update cache with new selectes shipping method
							$cache.shippingMethod = shippingMethodID;
							Sandro.footer.animateFormLabelCheck();
							Sandro.checkout.formValidatorRefresh($('#single-page-billing-form'));
							Sandro.checkout.formValidatorRefresh($('#single-page-shipping-form'));
						}
					});
				} else if ($(response).filter('div[data-checkoutredirecturl]').length > 0) {
					window.location = $(response).filter('div[data-checkoutredirecturl]').data('checkoutredirecturl');
				}
			},
			complete : function(jXhr, status) {
				app.progress.hide();
			}
		 });
	});

	$(document).on('submit', '#single-page-shipping-form, #single-page-billing-form', function(e) {
		e.preventDefault();
		e.stopPropagation();

		if ($(".form-global-error").length > 0) {
			return false;
		}
		//bind event on popin form closing that will remove once residual hash from the current url on hashchange
		if ($('div.sr_shippingOptionDiv').length > 0) {
			$(window).one('hashchange', function () {
				Sandro.checkout.removeHashInURL();
			});
		}


		if($('.avataxValidation').length > 0 && $(".add-new-section").is(":visible")){
			if($(".shipping-zip").hasClass('avatax-error')){
				return false;
			}
		}

		app.progress.show( e.target );

		var that = this;

		runOnePageAjaxCall(that);
	});

	$(document).on('change', '.avataxValidation', function(e) {
		var zipCodeValue = $(this).val();
		if( zipCodeValue !== "undefined" && zipCodeValue != null ){
			var correctZipCode = zipCodeValue.toString().match(/^\d{5}([\-]?\d{4})?$/);
			if(correctZipCode){
				Sandro.cart.refreshCartAmounts(zipCodeValue);
			}
		}
	});

	/**
	 * @function
	 * @description When user clicks on paymentMethod (credit card or paypal)
	 * the new payment value is updated in the hidden selected-payment-method input the form
	 */
	(function handlePaymentMethodSelection(){
		$(document).on('change click', '.radio-payment-control input', function(event) {
			//ID is either ccard or paypal
			var valSelectedPaymentMethod = $(this).data('js-payment-method-id'),
				idSelectedPaymentMethod = $(this).attr('id'),
				htmlSelectedPaymentMethod = $('.tab-control .payment-name[for='+idSelectedPaymentMethod+']').html(),
				forLabeltoUpdate = $('.payment-name.card-type'); // this label is shown after selecting the payment method

			//Display the proper payment method form
			$('.payment-method-list label').removeClass('selected');
			$('.payment-method-list label[for='+valSelectedPaymentMethod+']').addClass('selected');
			$('#payment-form-container').toggleClass('show-payment-content');

			//Update form's hidden input selectedPaymentMethod
			if(valSelectedPaymentMethod && valSelectedPaymentMethod.indexOf("paypal") >= 0) {
				$('span.paypal-text').addClass('show-text');
				changeSelectedPaymentMethodHiddenInput( $(this).data("js-paypal-value") );
			} else if(valSelectedPaymentMethod && valSelectedPaymentMethod.indexOf("ccard") >= 0) {
				forLabeltoUpdate.attr('for',idSelectedPaymentMethod).addClass('selected').html(htmlSelectedPaymentMethod+'<span class="back-button icon-uniE608 no-desktop"></span>'); // update label with selected value
				var ccType = $(this).attr('id');
				if(ccType.toLowerCase() == 'amex') {
					$('.info-helper').attr('data-helpertext',app.resources.CHECKOUT_PAYMENT_AMEX_HELPER);
				}
				else {
					$('.info-helper').attr('data-helpertext',app.resources.CHECKOUT_PAYMENT_CC_HELPER);
				}
				$('span.paypal-text').removeClass('show-text');
				// Remove required on cvn for maestro, add it back for the others
				var cvnField = $("input[id$='_creditCard_cvn']");
				var isCVNRequired = cvnField.hasClass("required"); // cvnField.is(":hidden"); // Commented lines for the case where we want the cvn to toggle between maestro and other cards
				idSelectedPaymentMethod != "maestro" ? $('.cvnmaestroasterisk').show() : $('.cvnmaestroasterisk').hide();

				if( idSelectedPaymentMethod == "maestro" ? isCVNRequired : !isCVNRequired ){
					cvnField.toggleClass("required");
					cvnField.closest("form").validate().resetForm();
					$("span.error-message").each(function(idx, ele){$(ele).remove()});
				}

				changeSelectedPaymentMethodHiddenInput( $(this).data("js-ccard-value") );
			} else {
				changeSelectedPaymentMethodHiddenInput( $(this).data("js-"+ valSelectedPaymentMethod +"-value") );
			}
			/**
			 * @function
			 * @description Change hidden input for selectedPaymentMethod with new values
			 */
			function changeSelectedPaymentMethodHiddenInput(selectedPaymentMethodValue) {
				$(".selected-payment-method").val(selectedPaymentMethodValue).data("js-ccard-value", selectedPaymentMethodValue);
			}
		});
	})();

	$(document).on('click', '.radio-creditcard', function (event) {
		if(event.target) {
			//$('#ccard-type-container').children().addClass("inactive");
			var currentid=$(event.target).attr('id');
			//$('#container-'+currentid).removeClass("inactive");
			$("input[name='dwfrm_billing_paymentMethods_selectedPaymentMethodID']").attr('value',$("#"+currentid).attr('value'));
		}
	});

	$(document).on('click', '#final-order-button', function(e) {
		e.preventDefault();
		e.stopPropagation();
		var that = this;
		if ( $('#single-page-payment-form').valid() ) {

			app.progress.show("div.left_column");

			$('.form-global-error').remove();

			if ($('#single-page-shipping-form').valid()) {
				$("#single-page-payment-form").submit();
			} else {
				alert('Please fill in all the information.')
			}
		}
	});

	// When the customer clicks on a store address for inStorePickup shipping method, update the shipping address
	// Uses a custom event because runOnePageAjaxCall is not accessible from outside the scope of Sandro.checkout.bindSubmitForms
	// and cannot be called directly in the store select button event handler
	$(document).on("refresh_shipping", function(){
		runOnePageAjaxCall($('#single-page-shipping-form button.submitCapAddress'), true);
	});

	// finalOrderButton is only used in the case of pickups, because we want to update the shipping address before submiting payment
	var runOnePageAjaxCall = function (that, noRefreshPaymentBlock, finalOrderButton) {
		var formElement = $(that).closest('form');
		var formData = new FormData(formElement.get(0));
		var btnName = formElement.find('button').attr('name').toString();
		var btnValue =  formElement.find('button').val().toString();
		var url = app.util.appendParamToURL(formElement.attr('action'), "format", "ajax");
		var finalOrderButton = finalOrderButton;

		formData.append(btnName,btnValue);

		$.ajax({
			url : url,
			data : formData,
			async: true,
			type : "POST",
			success : function (response) {
				if ($(response).find('#one-page-checkout').length > 0) {
					if ($("[name$='_shippingMethodID']:checked").val() == "777") {
						$cache.pickupInstoreID = $("[name$='_pickupStoreID']").val();
					} else if ($("[name$='_shippingMethodID']:checked").val() == "pointrelais") {
						if(finalOrderButton != undefined){
							// finalOrderButton is only defined when this method is called during the final-order submit, when using the pickup shipping method.
							runOnePageAjaxCall(finalOrderButton);
							// If finalOrderButton is defined, we are submitting payment and there is no need to update anything.
							// This also prevent the loader from disappearing when doing the shipping submit followed by the payment submit
							return;
						}
						$cache.address1 = $(".crosslogForm .shippingAddress1").val();
					}

					$('#one-page-checkout #address-blocks-container').replaceWith($(response).find('#one-page-checkout #address-blocks-container'));
					if (!noRefreshPaymentBlock || typeof noRefreshPaymentBlock === 'undefined') {
						$('#one-page-checkout #payment-form-container').replaceWith($(response).find('#one-page-checkout #payment-form-container'));
						$(document).trigger("payment.step");
					}
					$('#one-page-checkout .one-page-checkout-secondary').replaceWith($(response).find('#one-page-checkout .one-page-checkout-secondary'));

					// Set back the pickupstore id which was overwritten by the one-page-checkout-secondary replacement
					$("[name$='_pickupStoreID']").val($cache.pickupInstoreID);

					Sandro.footer.animateFormLabelCheck();
					$('.close-button')[0].click(); // No need to hide the loader on address modification because everything is discarded here
					Sandro.checkout.formValidatorRefresh($('#single-page-billing-form'));
					Sandro.checkout.formValidatorRefresh($('#single-page-shipping-form'));
					Sandro.checkout.formValidatorRefresh($('#single-page-payment-form'));

					if($(response).find('span.form-global-error')) {
						app.progress.hide();
					}
				} else if ($(response).filter('div[data-checkoutredirecturl]').length > 0) {
					// No need to hide the loader if we are reloading the page
					//below check is for US sites--if avatax error occurs on submitting the shipping form, we're redirecting and rendering it again along with the generic error message.
					//in other cases of redirect, it will simply redirect to the target template(else block)
					if($(response).filter('div[data-isError]').length > 0 && app.constants.CURRENT_SITEID == "Sandro-US") {
						app.progress.hide();
						window.location = $(response).filter('div[data-checkoutredirecturl]').data('checkoutredirecturl')+'#shipping-form-container';
						$('.estimation-tax-error').show();
						$(".shipping-zip").addClass('avatax-error');
					} else {
						window.location = $(response).filter('div[data-checkoutredirecturl]').data('checkoutredirecturl');
					}
				} else {
					// If for some reason we end up here (probably some kind of error), remove the loader
					app.progress.hide();
				}
			},
			error : function(jXhr, status, error) {
				// If we get an error, always remove the loader, even for final submit
				// The customer may have to correct his form and resubmit
				app.progress.hide();
			},
			cache: false,
			contentType: false,
			processData: false
		});
	}
}
/*
	Send Datalayer when user selects shipping method
*/
Sandro.checkout.sendShippingMethodDatalayer = function() {
	$(document).on('change', '.shipping-methods input[type="radio"]', function(){
		dataLayer.push({'event':'selectedShippingMethod','method':$(this).attr('value')});
	});
	$(document).on('change', '#ccard-type-container input[type="radio"]', function(){
		dataLayer.push({'event':'selectedPaymentMethod','method':$(this).attr('value')});
	});
}
/*
	Handle expandable menu on Basket Page
*/
Sandro.checkout.expandbasketOnMobile = function() {

	initBasketEl = function(){
		if($(window).width() < 767){
			$('#secondary').addClass('hide-details');
		}
		else {
			$('#secondary').removeClass('hide-details');
		}
	}

	$(document).on('click', '.section-header', function(){
		if($(window).width() < 767){
			$('#secondary').toggleClass('hide-details');
		}
	});

	initBasketEl();
	$(window).on('resize',initBasketEl);
}
/*
 * Update the events of credit card in billing
 */
Sandro.checkout.updateEventsCreditCard = function(){

	//Display block add a new payment option and update the events of credit card save
	$(document).on('updtRadioCreditCards', '.payment-form-header', function(){
		var fieldset = $(this).closest('fieldset');
		//init all input value and checked
		fieldset.find("input.radio-creditcard:hidden:checked").prop("checked", false);
		fieldset.find("input.radio-creditcard:visible:first").prop("checked",true);
		if (!$(this).hasClass('open')) {
			$('#ccard').prop('checked',true);
		}
		$('.form-global-error').remove();
	});

}

// this will allow tooltip showing on touch screen
Sandro.checkout.tooltipCode = function(){
	$( '#PaymentMethod_CREDIT_CARD .tooltip-cvn a' ).on('click', function(){
	      return false;
	});
};

Sandro.checkout.inputsFocus = function(){
	$(window).load(function() {
		$('.form-row input.additional-address')
			.focus(function(){
				$('.form-field-tooltip-mobile-display').hide();
			})
			.blur(function(){
				if (!$(this).val()) {
					$('.form-field-tooltip-mobile-display').show();
				}
			});
		$('.select-address .form-row select').on('change',function(e){
			if($('.form-row input.additional-address').val()){
				$('.form-field-tooltip-mobile-display').hide();
			}
		});
		if($('.form-row input.additional-address').val()){
			$('.form-field-tooltip-mobile-display').hide();
		}

		var messageInputSel = '.gift-message-text input[type=text]';
		/*$(document).on('focus', messageInputSel, function(e) {
			if($(window).width() < 767)
				$('label[for="' + $(this).attr('id') + '"]').hide();
		}).on('blur', messageInputSel, function(e) {
			if (!$(this).val()) {
				$('label[for="' + $(this).attr('id') + '"]').show();
			}
		});*/
		$('.gift-message-text input').on('focus',function(){
			$(this).attr('placeholder', '');
		});
		$(window).resize(function(e) {
			if($(window).width() >= 767)
				$('label[for="' + $(messageInputSel).attr('id') + '"]').show();
			else if ($(messageInputSel).val() || $(messageInputSel + ':focus').length > 0)
				$('label[for="' + $(messageInputSel).attr('id') + '"]').hide();
		});
	});
};

Sandro.checkout.customValidation = function() {

	$('form.checkout-shipping').on('change','[id$="_country"]',function(e){
		var $el = $(e.target);
		if(!!$el.val()){
			var $phoneEl = $el.closest('fieldset.capFieldset').find('.phone');
			//app.validator.element("#"+$phoneEl.attr('id'));
		}
	});
	
}

Sandro.checkout.formValidatorRefresh = function(formToRefresh) {
	if(formToRefresh.length > 0) {
		formToRefresh.data('validator', null).unbind('validate');
		formToRefresh.validate(app.validator.settings);
		app.validator.init();
	}
};

Sandro.checkout.billingAddressSelect = function() {

	function populateAddressFields(aid) {
		// For convenience, we use what's already there in app.js, simulating a change on the hidden select box
		$('#billing-form-container select[name*=addressList]').val(aid).change();
		$('#billing-form-container input[name$=selectedAddressID]').val(aid);
		app.prefixformat.init();
	}

	$(document).on('click', '#billing-form-container a.billing-address-add,#billing-form-container a.address-edit', function(e) {
		e.preventDefault();
		e.stopPropagation(); // prevent clicks on (a.address-edit) from reaching (label.address-details)

		//bind event on popin form closing that will remove once residual hash from the current url on hashchange
		if ($('div.sr_shippingOptionDiv').length > 0) {
			$(window).one('hashchange', function () {
				Sandro.checkout.removeHashInURL();
			});
		}

		$('html, body').stop().animate({scrollTop:0}, 500, function() {
			$('.select-address, .add-adress-container').slideUp();
			$('div.billing-fields').slideDown();
			$('a.back-button').show();
			$('a.close-button').hide();
		});

		$('div.billing-form input[name=addressbook]:checked').removeAttr('checked');

		if($(this).hasClass('billing-address-add')) {
			// Adding new address, reset validator and fields
			$('form.checkout-billing').validate().resetForm();
			$('div.billing-fields').find('input:text, input[type=tel]').val('');
			$('div.billing-fields input:radio[name$=salutation]').removeAttr('checked');
			if($("#dwfrm_billing_billingAddress_addressFields_salutation").length > 0){
				$("#dwfrm_billing_billingAddress_addressFields_salutation").attr('value', 2);
			}
			$('div.billing-fields input:radio[id$=customerms-bill]').prop('checked', true);
			$('#billing-form-container input[name$=selectedAddressID]').val('');
			$('.billing-fields').find('textarea[name $= "addressFields_message"]').val('');
			$('#billing-form-container .capSaisieFieldQualityCode').val(60);
			$('#billing-form-container div.addto-address-book').addClass('show');
			$('.select-address, .add-adress-container').slideUp();
		} else {
			$('#billing-form-container div.radioAdressCont').removeClass('active');
			$(this).closest('div.radioAdressCont').addClass('active');
			populateAddressFields($(this).closest('label').data('aid'));
			$('#billing-form-container div.addto-address-book').removeClass('show');
			Sandro.checkout.formValidatorRefresh($('#single-page-billing-form'));
			Sandro.footer.animateFormLabelCheck();
		}
		app.prefixformat.init();
	});

	$(document).on('click', '#billing-form-container div.select-address label.address-details', function() {
		//bind event on popin form closing that will remove once residual hash from the current url on hashchange
		if ($('div.sr_shippingOptionDiv').length > 0) {
			$(window).one('hashchange', function () {
				Sandro.checkout.removeHashInURL();
			});
		}

		// Remove any global error message when clicking on a new saved address
		$('.form-global-error').remove();
		var aid = $(this).data('aid');
		populateAddressFields(aid);
		$('#single-page-billing-form').find('button[name$="_submitBillingAddress"]').click();
	});


	$(document).on('click', '.billing-form a.back-button', function(event){
		event.preventDefault();
		$('.select-address, .add-adress-container').slideDown();
		$('div.billing-fields').slideUp();
		$('a.back-button').hide();
		$('a.close-button').show();
	});

	if ($('div.billing-fields span.error').length > 0) {
		$('div.billing-fields').show();
	}
}

Sandro.checkout.shippingAddressSelect = function() {

	function populateAddressFields(aid) {
		// For convenience, we use what's already there in app.js, simulating a change on the hidden select box
		$('#shipping-form-container select[name*=addressList]').val(aid).change();
		$('#shipping-form-container input[name$=shippingAddress_selectedAddressID]').val(aid);
		app.prefixformat.init();
	}

	$(document).on('click', '#shipping-form-container a.shipping-address-add,#shipping-form-container a.address-edit', function(e) {
		e.preventDefault();
		e.stopPropagation(); // prevent clicks on (a.address-edit) from reaching (label.address-details)

		//bind event on popin form closing that will remove once residual hash from the current url on hashchange
		if ($('div.sr_shippingOptionDiv').length > 0) {
			$(window).one('hashchange', function () {
				Sandro.checkout.removeHashInURL();
			});
		}
		$('html, body').stop().animate({scrollTop:0}, 500, function() {
			$('.select-address, .add-adress-container').slideUp();
			$('div.shipping-fields').slideDown();
			$('a.back-button').show();
			$('a.close-button').hide();
		});

		$('div.shipping-form input[name=addressbook]:checked').removeAttr('checked');

		if($(this).hasClass('shipping-address-add')) {
			// Adding new address, reset validator and fields
			$('form.checkout-shipping').validate().resetForm();
			$('div.shipping-fields').find('input:text, input[type=tel]').val('');
			$('div.shipping-fields input:radio[name$=salutation]').removeAttr('checked');
			if($("#dwfrm_singleshipping_shippingAddress_addressFields_salutation").length > 0){
				$("#dwfrm_singleshipping_shippingAddress_addressFields_salutation").attr('value', 2);
			}
			$('div.shipping-fields input:radio[id$=customerms]').prop('checked', true);
			$('#shipping-form-container input[name$=selectedAddressID]').val('');
			$('#shipping-form-container .shipping-fields').find('textarea[name $= "addressFields_message"]').val('');
			$('#shipping-form-container .capSaisieFieldQualityCode').val(60);
			$('#shipping-form-container div.addto-address-book').addClass('show');
			$('.select-address, .add-adress-container').slideUp();
		} else {
			$('#shipping-form-container div.radioAdressCont').removeClass('active');
			$(this).closest('#shipping-form-container div.radioAdressCont').addClass('active');
			populateAddressFields($(this).closest('label').data('aid'));
			$('#shipping-form-container div.addto-address-book').removeClass('show');
			Sandro.checkout.formValidatorRefresh($('#single-page-shipping-form'));
			Sandro.footer.animateFormLabelCheck();
		}
		app.prefixformat.init();
	});

	$(document).on('click', '#shipping-form-container div.select-address label.address-details', function() {
		//bind event on popin form closing that will remove once residual hash from the current url on hashchange
		if ($('div.sr_shippingOptionDiv').length > 0) {
			$(window).one('hashchange', function () {
				Sandro.checkout.removeHashInURL();
			});
		}

		// Remove any global error message when clicking on a new saved address
		$('.form-global-error').remove();
		var aid = $(this).data('aid');
		populateAddressFields(aid);
		$('#single-page-shipping-form').find('button[name$="_submitShippingAddress"]').click();
	});


	$(document).on('click', '.shipping-form a.back-button', function(event){
		event.preventDefault();
		$('.select-address, .add-adress-container').slideDown();
		$('div.shipping-fields').slideUp();
		$('a.back-button').hide();
		$('a.close-button').show();
		if($('.avataxValidation').length > 0 && $(".shipping-zip").hasClass('avatax-error')){
			$(".shipping-zip").removeClass('avatax-error');
			$('.estimation-tax-error').hide();
		}
	});

	if ($('div.shipping-fields span.error').length > 0) {
		$('div.shipping-fields').show();
	}
}

Sandro.checkout.updateMapSize = function() {
	var btnListMap = $('#listCmd'),
		storeList = $('#storeList');

	btnListMap.on('click', function(event){
		event.preventDefault();
		storeList.fadeToggle();
		$(this).parent().toggleClass('show');
		$(this).toggleClass('show');
		storeList.jScrollPane({
			autoReinitialise: true,
			mouseWheelSpeed : 20
		});
		var api = $('#storeList').data('jsp');
		if ($('.store.highlighted').length){
			api.scrollToElement($('.store.highlighted'));
		}

	});

	$('#moreStore').on('click', function(event){
		event.preventDefault();
		storeList.toggleClass('open');
		$(this).siblings('.listLocator').toggleClass('open');
		$(this).toggleClass('open');
	});
}




Sandro.checkout.validatePickupStore = function() {
	$('form.checkout-shipping button#continue').click(function() {
		/* If an avatax error occured */
		if($("#estimation-tax-value.error").length > 0) {
			$('html, body').animate({
		        scrollTop: $("#estimation-tax-value").position().top
		    }, 800);
			return false;
		}
	});
}

Sandro.checkout.contribCrosslog = function() {
	var currentAddress = encodeURIComponent(app.constants.CROSSLOG_DEFAULT_ADDRESS);

	function configureIframe() {
		if ($('.crosslog').data('location') != ''){
			currentAddress = $('.crosslog').data('location');
		}

		var params = {
			"UI":  app.constants.DEFAULTLLANGUAGECODE,
			"COUNTRY":  app.constants.DEFAULTCOUNTRYCODE,
			"QUERY": currentAddress,
			"WIDTH": 800,
			"ID_CLIENT" : app.constants.CROSSLOG_CLIENT_ID,
			"ID_COMMANDE" : app.constants.CROSSLOG_COMMANDE_ID
		};

		if ($(window).width() < 768) {
			params.MOBILE = 1;
			params.WIDTH = 600;
		} else {
			params.FORCEWEB = 1;
		}

		var iframeUrl = app.constants.CROSSLOG_IFRAME_URL + "?";
		for(var param in params) {
			iframeUrl += param + "=" + params[param] + "&";
		}

		$('.crosslog').attr('width', '100%');
		$('.crosslog').attr('height' ,'525');
		$('.crosslog').attr('src', iframeUrl);
		$('.crosslog').css('margin-top', 16);
	}

	configureIframe();
}

Sandro.checkout.crosslogSubmitHandler = function(event, that) {
	var firstName = $("#billingform .firstName").val();
	var lastName = $("#billingform .lastName").val();

	if (firstName && lastName && $("[name$='_shippingMethodID']:checked").val() == "pointrelais") {
		event.preventDefault();
		var currentTarget = event.currentTarget;
		var addr = {
			first_name: null,
			last_name: null,
			address1: null,
			country: null,
			city: null,
			zip: null
		};

		$.ajax({
			url: app.urls.crosslog,
			method: "GET",
			contentType: 'text/plain',
			async: false,
			success : function (data) {
				if(!data) {
					return;
				}

				var parsedAddr = $.parseXML(data);

				addr = {
						first_name: firstName,
						last_name: lastName,
						phone: $(".crosslogForm .billingPhone").val(),
						code: parsedAddr.getElementsByTagName("Code")[0].innerHTML,
						name: parsedAddr.getElementsByTagName("Name")[0].innerHTML,
						address1: parsedAddr.getElementsByTagName("Address1")[0].innerHTML,
						address2: parsedAddr.getElementsByTagName("Address2")[0].innerHTML,
						country: parsedAddr.getElementsByTagName("CountryCode")[0].innerHTML,
						city: parsedAddr.getElementsByTagName("City")[0].innerHTML,
						zip: parsedAddr.getElementsByTagName("PostalCode")[0].innerHTML
				}

				if (addr.first_name && addr.last_name &&
						addr.address1 && addr.country && addr.city && addr.zip) {
					$(".crosslogForm .billingPhone").val(addr.phone);
					$(".crosslogForm .shippingFirstName").val(addr.first_name);
					$(".crosslogForm .shippingLastName").val(addr.last_name);
					$(".crosslogForm .shippingAddress1").val(addr.address1);
					$(".crosslogForm .shippingMessage").val(addr.address2);
					$(".crosslogForm .shippingCountry").val(addr.country);
					$(".crosslogForm .shippingCity").val(addr.city);
					$(".crosslogForm .shippingZip").val(addr.zip);
					$(".crosslogForm .shippingRelayID").val(addr.code);
					$(".crosslogForm .shippingRelayName").val(addr.name);
				}
			}
		});
	}
}


Sandro.checkout.sendNewsletterTag = function() {
	$('form.checkout-shipping button#continue').click(function() {
		var form = $('form.checkout-shipping');
		var newsletterOptin = $('input[type=checkbox][name=dwfrm_newsletteroptin_newsletteroptin]');
		if (form.valid() && newsletterOptin.is(':checked')) {
			$(document).trigger("newsletter.subscription");
		}
	});
}

Sandro.checkout.selectPickupStore = function() {
	$('.pickup-store-selection').on('click', 'a.btnSelectStore, a.selectLastPickupStore', function(e) {
		e.preventDefault();

		app.progress.show("div.left_column");

		$('.store-list').find('.shopchecked').removeClass('shopchecked');
		Sandro.checkout.updateStoreButtons($(this).data("storeid"));
		if ($(window).width() > 767) {
			if ($('#storeList').data('jsp')){
				var api = $('#storeList').data('jsp');
				api.scrollToElement($('.store.highlighted'));
			}

		}
		$("html, body").animate({
			scrollTop: $('#address-blocks-container').offset().top
		}, 1000);
		// Trigger submit of shipping address (actually submit of pickupStore id)
		// By doing this here, we ensure that all values in the form are actually set at this point before proceeding with the address data
		$(document).trigger("refresh_shipping");
	});
}

Sandro.checkout.updateStoreButtons = function(storeID) {
	$('.pickup-store-selection').find('a.btnSelectStore, a.selectLastPickupStore')
									.text(app.resources.SELECT_THIS_STORE)
									.filter('[data-storeid=' + storeID + ']')
										.text(app.resources.STORE_SELECTED)
										.addClass('shopchecked')
									.end()
								.end()
								.find('input[type=hidden][name$=_pickupStoreID]')
									.val(storeID);
	if ($('#panel .errorGeo').length){
		$('#panel .errorGeo').remove();
	}

	//$('#one-page-checkout').find('.address-block-container').removeClass('disabled-block');
}

Sandro.checkout.showLastPickupStoreOnMap = function() {
	$('.pickup-store-selection').on('click', 'a.showStoreOnMap', function(e) {
		e.preventDefault();
		e.stopPropagation();
		var storeID = $(this).data("storeid");
		Sandro.storelocator.highlightStore(storeID);

	});
	$(document).on('click', '.seeHours', function(event){
		event.preventDefault();
		$(this).closest('.store').find('.storeHours').slideToggle();
	})
}




Sandro.confirmation.bindEvents = function() {

	function showGuestPopinCreateAccount() {
		var dialogContainer = $('div.login-create-account');
		dialogContainer.dialog({
			dialogClass: 'checkout-style update-form-style login-popin',
			modal : true,
			resizable : false,
			draggable: false,
			title: dialogContainer.data('popintitle'),
			create: function( event, ui ) {
				$('.login-popin input.confirm-email').closest('.form-row').addClass('confirm');
				$('.login-popin input.input-text-pw.confirm-password').closest('.form-row').addClass('confirm');
				$('.login-popin .submitBtn').on('click', function(e){
					e.preventDefault();
					var mailCopy = ( $('.login-popin input.email').val());
					window.location.href = app.urls.registerPage + "?email="+mailCopy;
				});
			}
		});
	}

	function showKnownGuestLogin() {
		var dialogContainer = $('div.confirmation-login-box');
		$('div.confirmation-login-box').dialog({
			dialogClass: 'checkout-style update-form-style login-popin',
			modal : true,
			resizable : false,
			draggable: false,
			title: dialogContainer.data('popintitle')
		});
	}

	var delayPopin = $(window).width() < 767 ? app.constants.GUEST_REGISTER_POPIN_DELAY_MOBILE : app.constants.GUEST_REGISTER_POPIN_DELAY_DESKTOP;

	try {
		delayPopin = parseInt(delayPopin);

		// First, check if a known guest was detected
		if ($('div.confirmation-login-box').length > 0) {
			setTimeout(showKnownGuestLogin, delayPopin);
		} else {
			// Logged in or entirely new guest
			$('div.login-create-account').each(function() {
				setTimeout(showGuestPopinCreateAccount, delayPopin);
			});
		}
	} catch(e) {}


	$('div.confirmation-guest-create-account button').click(function(e) {
		e.preventDefault();
		showGuestPopinCreateAccount();
	});

	$('div.login-create-account button.arrow-left').click(function() {
		$('div.login-create-account').dialog('close')
	})

	$('.details-title').click(function() {
		$('.detailed-summary').slideToggle('slow',function(){
			if($('.detailed-summary').is(':hidden'))
			{
				$('.details-title').addClass('close');
			}else{
				$('.details-title').removeClass('close');
			}
		});

	});

	$('div.confirmation-known-guest-login a.button').click(function(e) {
		e.preventDefault();
		showKnownGuestLogin();
	});


}

/*
	Sandro Newsletter Inscription in Footer
	Form available at components/footer/footernewsletter.isml
	Refonte SMCP-3050 (2017)
	1- Verify valid e-mail address
	2- Display Man / Woman Inscription Buttons
	3- Subscribe to newsletter via ajax call
*/
Sandro.footer.newsletterInscription = function() {

	//1- Verify valid e-mail address
	var validNLemail = false;
	var nlemail;
	var nlcountry;

	$('#newsletterinscription-form').on('keyup keypress', function(e) {
		var keyCode = e.keyCode || e.which || window.e.keyCode;
		if (keyCode === 13) {
			e.preventDefault();
			//2- Display Man / Woman Inscription Buttons
			encodeEmailAndCountryForNewsletter(this);
		}
	});

	$(".newsletterinscription-verifyBtn").on('click', function(e) {
		e.preventDefault();
		if (!$(this).closest('form').valid()) {
			return false;
		}
		if ($(".flipNews").length) {
			$(".flipNews").removeClass("flipNews")
		} else {
			//2- Display Man / Woman Inscription Buttons
			encodeEmailAndCountryForNewsletter(this);
		}
		validNLemail = true;
	});

	$('.newsInput').focus(function() {
		$(this).removeClass('error');
	});

	$('.changeCountryContent').on('change', function(event){
		window.location.replace(this.value);
	});

	$(".newsletterinscription-subscribe a, .input-container a.gender-button").on('click', function(e) {
		e.preventDefault();
		var btn_clicked = this;
		if (validNLemail) {
			var targetUrl = $(this).attr("href");
			targetUrl += "&email=" + nlemail;
			//For RE sites, select input exists
			if (nlcountry && nlcountry !== "undefined") {
				targetUrl += "&country=" + nlcountry;
			}
			$(btn_clicked).addClass("selected");
			if (!$(btn_clicked).is('.popin-newsletter a')) {
				$(".footer-newsletterinscription").addClass("select-done");
			}
			//3- Subscribe to newsletter via ajax call
			$.ajax({
			 	url: targetUrl
			}).done(function(data) {
				var res = data.Result;
				var customerAlreadyMember = data.CustomerAlreadyMember;
				if(res != null && res != '' && res == 'failed') {
					$("span.newsletter-form-message").empty();
					$("span.newsletter-form-message").append($("#newsletterinscription-form").data('msgcomerror'));
				}
				else {
					//Display response message to user
					$("span.newsletter-form-message").empty();
					var newsletterMessage = customerAlreadyMember ? $("#newsletterinscription-form").data('msgalreadydone') : $("#newsletterinscription-form").data('msgdone');
					//$("#newsletterinscription-form").hide();
					$(btn_clicked).removeClass("selected");
					if ($(btn_clicked).is('.popin-newsletter a')) {
						$("span.newsletter-form-message").append(newsletterMessage);
						$('.popin-news-inscription-result').addClass('show').prev('.popupNewsletterForm').hide();
					} else {
						$("span.newsletter-form-message").append(newsletterMessage);
						$(".news-inscription-result").addClass("show").prev('.popupNewsletterForm').hide();
					}
					//GTM : Send dataLayer
					$(document).trigger("newsletter.subscription");
				}
				// close popup
				setTimeout(function(){
					$('#newsletter-popup-wrapper').fadeOut();
				}, 8000);
			}).fail(function() {
				$("span.newsletter-form-message").empty();
				$("span.newsletter-form-message").append($("#newsletterinscription-form").data('msgcomerror'));
			});
		} else {
			//display revalidate e-mail message
			$("span.newsletter-form-message").empty();
			$("span.newsletter-form-message").append($("#newsletterinscription-form").data('msgerror'));
		}
	});

	function encodeEmailAndCountryForNewsletter(clickedElement) {
		var subForm = $(clickedElement).closest('form'),
			field = subForm.find('input[name=nlemail]');
		if (field.length < 1)
			field = subForm.find('input[id=nlemail]');
		nlemail = field.val();
		console.log(nlemail)
		if (field.hasClass('error-message')) {
			console.log('vjkl');
			$("span.newsletter-form-message").empty();
			$("span.newsletter-form-message").append($("#newsletterinscription-form").data('emailerror'));
		} else {
			console.log('ngon');
			$("span.newsletter-form-message").empty();
			nlcountry = $('#newsletterinscription-form #nlcountry').length ? encodeURIComponent($('#newsletterinscription-form select[id=nlcountry]').val()) : false;
			nlemail = encodeURIComponent(nlemail);
			//2- Display Woman / Man Section
			if ($(clickedElement).closest('.popin-newsletter').length) {
				$(clickedElement).closest('.homePage').addClass('flipNews');
			} else {
				$('body').addClass('flipNews');
			}
		}
	}
}

Sandro.footer.initNewsletterPopin = function(){

	$('.civility').each(function() {
		$(this).click(function() {
			$('.civility').removeClass('selected');
			if ($(this).find('input').attr('checked')) {
				$(this).addClass('selected');
			}
		})
	})

	$('.popin-input-radio input').on('change', function(){
		var V_href = app.urls.newsletterGender;
		$('.gender-button').attr('href', V_href.replace(/salutation=./, "salutation=" + $(this).val()));
	});

	function getQueryVariable(variable) {
	    var query = window.location.search.substring(1);
	    var vars = query.split("&");
	    for (var i = 0; i < vars.length; i++) {
	        var pair = vars[i].split("=");
	        if (pair[0] == variable) {
	            return pair[1];
	        }
	    }
	    return null;
	}

	// Add validator methods on newsletter form
	// Error messages empty because taken care of in another event (see Sandro.splash.events)
	$.validator.addMethod("nlcountry", function(value, elt){return value;}, '');

	var cookie = $.cookie("sandronewsletterpopin");
	var returningUserOrderConfirmed = getQueryVariable('newsletter');
	if(cookie == null)
	{
		$.cookie('sandronewsletterpopin', 'true', { expires: 365, path: '/' });
		if($('#deactivateNlPopin').val() == 'false') {
			$('.showNewsletterSubscription').trigger("click");
		}

	} else if(returningUserOrderConfirmed == '1') {
		$.cookie('sandronewsletterpopin', 'true', { expires: 365, path: '/' });
		$('.showNewsletterSubscription').trigger("click");
	}

	$(document).on('click tap touchstart', '.btnCloseNewsletter', function(e){
		e.preventDefault();
		$(this).closest('#newsletter').dialog('destroy');
		$('.greyLayer').remove();
	});

	if($('.ie8').length){
		$('.ie8').find('.input-checkbox').click(function() {
		});
	}

	$(".btn-nlhomme, .btn-nlfemme").on('click', function(e){
		$('.popupNewsletterForm').submit();
	})

	// Event for the newsletter in popup
	$('.popupNewsletterForm').on('submit', function(e) {
		e.preventDefault();
		if ($(this).valid()) {
			var email = encodeURIComponent($(this).find('input#nlemail').val());
			var country = encodeURIComponent($(this).find('select#nlcountry').val());
			var sex = $(this).find('input#nlhomme').is(':checked') ? $(this).find('input#nlhomme').val() : $(this).find('input#nlfemme').val();
			var targetUrl = $(this).attr("action") + "?salutation=" + sex;
			callAjaxNewsletter(targetUrl,email,country,$(this));
		}
	});

	// Event for newsletter in splash page
	$('.splashNewsletterForm').on('submit', function(e) {
		e.preventDefault();
		if ($('.gender a').hasClass('selected') && $(this).valid()) {
			var email = encodeURIComponent($(this).find('input#nlemail').val());
			var country = encodeURIComponent($(this).find('select#nlcountry').val());
			var targetUrl = $(this).find('a.male').hasClass('selected') ? $(this).find('a.male').attr('href') : $(this).find('a.female').attr('href');
			callAjaxNewsletter(targetUrl,email,country,$(this));
		}
	});

	var callAjaxNewsletter = function(targetUrl, email, country, currElt) {
		var delayBeforeDisplayPopup =  $('#delayAfterResponsePopupNewsletter').val() * 1000;

		targetUrl += "&email=" + email;

		if (country && country !== "undefined") {
			targetUrl += "&country=" + country;
		}

		$.ajax({
		 	url: targetUrl
		}).done(function(data) {
			var res = data.Result;
			var customerAlreadyMember = data.CustomerAlreadyMember;

			if(res != null && res != 'none' && res != '')
			{
				var msgDone = (customerAlreadyMember === null || customerAlreadyMember === false) ? "msgdone" : "msgalreadydone";

				if(currElt.attr('id') == 'popupNewsletterForm'){
					$("div.home-popup-newsletter p").empty().append($("#submitnewsletter").data(msgDone));;
					$("div.home-popup-newsletter").show();
					$("div.home-popup-newsletter").css( "display", "table");
					$("form#popupNewsletterForm").hide();

					$(document).trigger("newsletter.subscription");

					setTimeout(function(){
						$('.home-popup').stop().fadeOut();
					}, delayBeforeDisplayPopup);
				}
				else{
					// Update footer newsletter subscription form with success message
					$('.newsletter').addClass('success');
					$('.newsletter-form-message').addClass('success').html(app.resources.SPLASH_NL_SUCCESS).show();

					$("span.newsletter-form-message").empty();
					$("span.newsletter-form-message").append($("#news-form").data(msgDone));
					$("div.footerNewsletter").show();
					$("div.footerNewsletter").css( "display", "table");
					$("form.news-form").hide();

					$(".newsletterErrorMsg").empty();
					$("div.newsletterEndMsg > span").empty();
					$("div.newsletterEndMsg > span").append($("#submitnewsletter").data(msgDone));
					$("div.newsletterEndMsg").show();
					$("div.newsletterEndMsg").css( "display", "table");
					$("form.newsletterEndForm").hide();
					$("p.mandatory").hide();

					$(document).trigger("newsletter.subscription");
				}
			}
			else
			{
				$("span.newsletter-form-message").empty();
				$("span.newsletter-form-message").append($("#news-form").data("msgerror"));
				$(".newsletterErrorMsg").empty();
				$(".newsletterErrorMsg").append($("#submitnewsletter").data("msgerror"));

				if(currElt.attr('id') == 'popupNewsletterForm'){
					$("div.home-popup-newsletter p").empty().append($("#submitnewsletter").data("msgerror"));;
					$("form#popupNewsletterForm").hide();
				}
			}
		}).fail(function() {
			$(".newsletterErrorMsg").empty();
			$(".newsletterErrorMsg").append($("#submitnewsletter").data("msgcomerror"));

			if(currElt.attr('id') == 'popupNewsletterForm'){
				$("div.home-popup-newsletter p").empty().append($("#submitnewsletter").data("msgcomerror"));;
				$("form#popupNewsletterForm").hide();
			}
		});
	};

};

Sandro.delivery = Sandro.delivery || {};

Sandro.delivery.bindEvents = function(){
	$(".openPopinStores").on("click", function(e){
		e.preventDefault();

		$("#popin-delivery").dialog({
		    closeText: app.resources.CLOSE,
		    draggable: false,
		    dialogClass : 'delivery-dialog',
		    height: "auto",
	        width: "600px",
		    modal: true,
		    position: "center",
		    show : {
				complete: function() {
					if($('#popin-delivery').length){

						var getWidthDialog;
							getHeightDialog = $('.delivery-dialog').height();

						if($(window).width() < 768){
							getWidthDialog = "100%";
						} else{
							getWidthDialog = "600px";
						};

						$('.delivery-dialog').css({
							'width' : getWidthDialog,
							'margin-left' : -(getWidthDialog / 2),
							'top' : $(window).scrollTop() + "px",
							'left' : 0,
							'right' : 0,
							'margin' : '0 auto',
							'min-width' : '320px'
						});

					}
				}
			},
		    close: function() {
		    	$(this).addClass("hidden");
		    }
		});

		$("#popin-delivery").removeClass("hidden");
	});
};

Sandro.content = Sandro.content || {};

Sandro.content.bindEvents = function(){
		Sandro.content.expandGuide('.titleSexeTb','.expDiv','.titleSexeTb a','.mobTabGuid', 'h2', 'click tap', '.tableGuid');
		Sandro.content.expandSiteMap('.titleSiteMap','.expDiv','.titleSiteMap a','.contentSiteMap', 'h2', 'click tap ', '.mapCol');
		Sandro.content.expandFaq('.toggleFaq','.expDiv','a','.descFaq', 'h3', 'click tap', '.blockFaq');
		Sandro.content.expandFaqMobile('.expMobTitle','.expDiv','a','.catFaq', 'div', 'click tap', '.wrapperFaq');
		Sandro.content.selectFaq('click tap');
		//Sandro.content.resize();
		Sandro.content.resizeRemoveAttrFaq();
		Sandro.content.activeLinkQuickView();
		Sandro.content.quickAddToCart();
};

Sandro.content.quickAddToCart = function(){
	var app = app || window.app;
	if ($('.prd-quick-sz-atc').length < 1)
		return;
	$(document).on('click.quickcart', '.prd-quick-sz-atc', function(e) {
		e.preventDefault();
		var button = $(this),
			container = button.closest('.prd-quick-sz'),
			url = button.data('quickview');

		if (container.hasClass('sz-loaded')) {
			container.addClass('active');
		} else {
			button.prop('disabled', true);
			$.ajax({
				url: url,
				success: function(data) {
					button.prop('disabled', false);
					container.addClass('sz-loaded active')
					container.find('ul').html(
						$(data).find('ul.swatches.size').html()
					);
				}
			});
		}

	}).on('click.quickcart', 'body', function(e){
		var target = $(e.target),
			szEl = target.hasClass('prd-quick-sz') ? target : target.closest('.prd-quick-sz').length ? target.closest('.prd-quick-sz') : false;
		$('.prd-quick-sz').removeClass('active');
		szEl && szEl.hasClass('sz-loaded') && szEl.addClass('active');
	}).on('click.quickcart', '.prd-quick-sz .swatchanchor', function(e) {
		e.preventDefault();
		var prod_url = $(this).attr('href'),
			form = false,
			cart_action = app.util.ajaxUrl($('.prd-quick-sz').data('cart-action'));
		$.ajax({
			url: prod_url,
			success: function(data) {
				form = $(data).find('.pdpForm');
				if (form && form.length) {
					$.ajax({
						url : cart_action,
						type : 'POST',
						cache: false,
						data : form.serialize()
					}).done(function (response) {
						app.minicart.show(response);
						Sandro.minicart.bindEvents();
						Sandro.cart.bindEvents();
						// app.minicart.open();
					});
				}
			}
		})
	});
};

Sandro.content.activeLinkQuickView = function() {
	if($('.activeQuickView').length){
		$('.activeQuickView').on('click', function(e) {
			e.preventDefault();
			var myWidth = $(this).attr('data-width');
			if($(window).width() > 767) {
				myWidth = 880;
			}else{
				myWidth = 310;
			}

			app.quickView.show({
				url : $(this).attr("href"),
				width: myWidth
			});
			Sandro.product.bindAddToWishlist();
			$(document).on('click', '.add-to-cart-disabled', function(){
				Sandro.product.cannotaddtocart(this);
			});

			return false;
		});
	}

}

Sandro.content.classEventGuid = function(){
	var el = {
			btnExpandGuid : '.titleSexeTb',
			classExpand : 'expDiv',
			parentGrid : '#sizeGrid'
		};

		$(el.btnExpandGuid).addClass(el.classExpand);
};

Sandro.content.classEventSiteMap = function(){
	var el = {
			btnExpandGuid : '.titleSexeTb, .toggleFaq, .expMobTitle',
			classExpand : 'expDiv'
		};

		$(el.btnExpandGuid).addClass(el.classExpand);
};

Sandro.content.expandGuide = function(element, classExpand, trigger, contentSlide, typeBlock, mode, elementGuid){
	var blockTab = $(element);
	Sandro.content.classEventGuid();
	$(document).on(mode, '.titleSexeTb a',  function(event){
		event.preventDefault();
		if($(window).width() < 767 ){
			$(this).toggleClass('active');
			$(this).closest(elementGuid).siblings(elementGuid).find(trigger).removeClass('active');
			$(this).closest(typeBlock).next(contentSlide).slideToggle();
			$(this).closest(elementGuid).siblings(elementGuid).find(contentSlide).slideUp();
		}
	});
}

Sandro.content.expandSiteMap = function(element, classExpand, trigger, contentSlide, typeBlock, mode, elementGuid){
	var blockTab = $(element);
	Sandro.content.classEventSiteMap();
	$(document).on(mode, '.titleSiteMap a',  function(event){
		event.preventDefault();
		if($(window).width() < 767){
			$(this).toggleClass('active');
			$(this).closest(elementGuid).siblings(elementGuid).find(trigger).removeClass('active');
			$(this).closest(typeBlock).next(contentSlide).slideToggle();
			$(this).closest(elementGuid).siblings(elementGuid).find(contentSlide).slideUp();
		}
	});
}

Sandro.content.expandFaq = function(element, classExpand, trigger, contentSlide, typeBlock, mode, elementGuid){
	var blockTab = $(element);

	Sandro.content.classEventSiteMap();

	$(document).on(mode, '.btn-expend',  function(event){
		event.preventDefault();
		if ($(this).prev('ul').find('li.to-hide').is(':visible') == true) {
			$(this).html('<span class="icon-arrow-down"></span><span>'+$(this).data('close')+'</span>');
		}else{
			$(this).html('<span class="icon-arrow-up"></span><span>'+$(this).data('open')+'</span>');
		}

		$(this).prev('ul').find('li.to-hide').slideToggle();
	});
};
Sandro.content.expandFaqMobile = function (element, classExpand, trigger, contentSlide, typeBlock, mode, elementGuid){
//	var blockTab = $(element);
//			Sandro.content.classEventSiteMap();
//			$('.wrapperFaq').find(element).eq(0).find('a').addClass('active');
//			$(document).on(mode, '.expMobTitle a',  function(event){
//					event.preventDefault();
//						$(this).toggleClass('active');
//						$(this).closest(elementGuid).siblings(elementGuid).find(trigger).removeClass('active');
//
//						$(this)
//						.closest(typeBlock)
//						.prevAll(element)
//						.find(trigger)
//						.removeClass('active');
//
//						$(this)
//						.closest(typeBlock)
//						.nextAll(element)
//						.find(trigger)
//						.removeClass('active');
//
//						// toggle contnet Faq
//						$(this)
//						.closest(typeBlock)
//						.next(contentSlide)
//						.slideToggle();
//
//						// close Toggle siblings
//						$(this)
//						.closest(typeBlock)
//						.next(contentSlide)
//						.prevAll(contentSlide)
//						.slideUp();
//
//						// close Toggle siblings
//						$(this)
//						.closest(typeBlock)
//						.next(contentSlide)
//						.nextAll(contentSlide)
//						.slideUp();
//			});
}

Sandro.content.selectFaq = function(mode){

	$('.menuFaq').find('.itemFaq').eq(0).find('.btFaq').addClass('active');

	if ($('.contentFaq').length){
		$('.menuFaq').appendTo('#navigation');
	}

	$('.btFaq').on(mode, function(e){

		 var idTomove = $(this).attr("href");
		  $('html, body').animate({
			  scrollTop:$(idTomove).offset().top
		  }, '800');

		$(this).closest('li').siblings('li').find('a').removeClass('active');
		$(this).addClass('active');

	});
};

Sandro.content.resize = function(){
	var el = {
			blockGuid : '.mobTabGuid , .contentSiteMap',
			btnExpandGuid : '.titleSexeTb , .titleSiteMap',
			addClassExpand : 'expDiv',
			classExpand : '.expDiv',
			trigger : 'a',
			contentSlide : '.mobTabGuid , .contentSiteMap',
			typeBlock : 'h2',
			mode : 'click tap touchstart',
			elementGuid :'.tableGuid, .mapCol'
		};

		$(window).resize(function(){
				if($(window).width() > 767){
					$(el.blockGuid).removeAttr('style');
					$(el.btnExpandGuid).find(el.trigger).removeClass('active');
					$(el.btnExpandGuid).removeClass(el.addClassExpand);
					$(el.btnExpandGuid).find(el.trigger).off('click');
				}else{
					$(el.btnExpandGuid).addClass(el.addClassExpand);

				}
		});
};

Sandro.content.resizeRemoveAttrFaq = function(){
	var el = {
		blockGuid : '.descFaq',
		btnExpandGuid : '.toggleFaq',
		addClassExpand : 'expDiv',
		classExpand : '.expDiv',
		trigger : 'a',
		contentSlide : '.descFaq',
		typeBlock : 'h3',
		mode : 'click tap touchstart',
		elementGuid :'.ctFaq',
		catFaq : '.catFaq',
		parentWrap : '.wrapperFaq'
	};

//	$(window).resize(function(){
//		$(el.btnExpandGuid).addClass(el.addClassExpand);
//		if($(window).width() < 767){
//			$(el.blockGuid).removeAttr('style');
//			$(el.catFaq).removeAttr('style');
//		}else{
//			$(el.parentWrap).find(el.catFaq).eq(0)
//			.css({
//				'position' : 'static',
//				'display' : 'block'
//			})
//			.siblings(el.catFaq)
//			.css({
//				'position' : 'absolute',
//			});
//		}
//	});
}

/*
*	Home Vimeo Player
*/
Sandro.home.VimeoPlayer = (function () {
	function VimeoPlayer(playerInDom, isBigPush) {
        this.isBigPush = isBigPush;
        this.playerInDom = playerInDom;
        this.vimeoPlayer = {
			hasResult : false,
			isDataPopulated : false,
			vimeoDataQuery : null,
			vimeoPlayerResponse : null,
			apiUrl:'https://vimeo.com/api/oembed.json'
		};
    }

    VimeoPlayer.prototype.getVimeoDataFromMarkup = function () {
    	var container = this.playerInDom;
    	var videoUrl = container.length > 0 ? container.attr("data-url") : "";
    	var auto = container.length > 0 ? (container.attr("data-autoplay")=="true") : false;
    	var data = {
    		url: videoUrl,
    		height : this.isBigPush ? 520 : 250,
    		width : this.isBigPush ? 960 : 470,
    		color : "cfff02",
    		title:false,
    		portrait:false,
    		autoplay:auto,
    		byline:false
    	};

    	this.vimeoPlayer.vimeoDataQuery = data;
    	this.vimeoPlayer.isDataPopulated = true;
    };

    VimeoPlayer.prototype.disableAutoplay = function () {
    	this.vimeoPlayer.vimeoDataQuery.autoplay=false;
    };

    VimeoPlayer.prototype.getVimeoPlayer = function () {
    	var root = this;
    	if(this.vimeoPlayer.isDataPopulated){
    		$.ajax({
    			dataType: 'jsonp',
    			crossDomain: true,
    			data: root.vimeoPlayer.vimeoDataQuery,
    			url: root.vimeoPlayer.apiUrl
    		})
    		.done(function(data){
    			root.vimeoPlayer.vimeoPlayerResponse = data;
    			if(root.vimeoPlayer.vimeoPlayerResponse && root.vimeoPlayer.vimeoPlayerResponse.html){
    				root.vimeoPlayer.hasResult = true;
    				if(typeof(root.injectVimeoPlayer) === "function"){
    					root.injectVimeoPlayer(root.playerInDom, root.vimeoPlayer);
    				}
    			}
    		})
    		.fail(function(){});
    	}
    };

    // CallBack
    VimeoPlayer.prototype.injectVimeoPlayer = function (playerInDom, vimeoPlayer) {
    	if(vimeoPlayer.hasResult){
    		playerInDom.html(vimeoPlayer.vimeoPlayerResponse.html);
    	}
    };

    VimeoPlayer.prototype.displayVimeoPlayer = function () {
    	if(this.playerInDom.length > 0){
			this.getVimeoDataFromMarkup();
			var root = this;

			$(window).resize(function(){

				if($('body').width() <= 767){
					if(root.isBigPush)
					{
						root.playerInDom.css({width:'310px', height:'205px'});
						root.vimeoPlayer.vimeoDataQuery.height = 205;
						root.vimeoPlayer.vimeoDataQuery.width = 310;
					}
					else
					{
						root.playerInDom.css({width:'153px', height:'126px'});
						root.vimeoPlayer.vimeoDataQuery.height = 126;
						root.vimeoPlayer.vimeoDataQuery.width = 153;
					}
				}
				else
				{
					if(root.isBigPush)
					{
						root.playerInDom.css({width:'960px', height:'520px'});
						root.vimeoPlayer.vimeoDataQuery.height = 520;
						root.vimeoPlayer.vimeoDataQuery.width = 960;
					}
					else
					{
						root.playerInDom.css({width:'470px', height:'250px'});
						root.vimeoPlayer.vimeoDataQuery.height = 250;
						root.vimeoPlayer.vimeoDataQuery.width = 470;
					}
				}

				root.disableAutoplay();
				root.getVimeoPlayer();
			});

			if($('body').width() <= 767){
				if(this.isBigPush)
				{
					this.playerInDom.css({width:'310px', height:'205px'});
					this.vimeoPlayer.vimeoDataQuery.height = 205;
					this.vimeoPlayer.vimeoDataQuery.width = 310;
				}
				else
				{
					this.playerInDom.css({width:'153px', height:'126px'});
					this.vimeoPlayer.vimeoDataQuery.height = 126;
					this.vimeoPlayer.vimeoDataQuery.width = 153;
				}
			}
			else
			{
				if(this.isBigPush)
				{
					this.playerInDom.css({width:'960px', height:'520px'});
					this.vimeoPlayer.vimeoDataQuery.height = 520;
					this.vimeoPlayer.vimeoDataQuery.width = 960;
				}
				else
				{
					this.playerInDom.css({width:'470px', height:'250px'});
					this.vimeoPlayer.vimeoDataQuery.height = 250;
					this.vimeoPlayer.vimeoDataQuery.width = 470;
				}
			}

			this.playerInDom.show();
			this.getVimeoPlayer();
		}
    };

	return VimeoPlayer;
})();

Sandro.newspaper.bindEvents = function(pageContext, isLogoBlack){

	Sandro.newspaper.scrollTo();

	Sandro.newspaper.pageLogoBlack(pageContext, isLogoBlack);

	if(pageContext == "newspaper_article"){
		Sandro.newspaper.responsiveLayout();
		pageContext = "newspaper"
	}

	Sandro.newspaper.navigation();

	Sandro.newspaper.selectArticle();

	Sandro.newspaper.callSlider();
}

Sandro.newspaper.scrollTo = function(){
	var scrollTo = {
        init: function() {
            var scrollTopCTA = $('#scroll-top-cta');
            var scrollContentCTA = $('#scroll-down-cta');
            if (scrollTopCTA.length !== 0) scrollTopCTA.click(this.top);
            if (scrollContentCTA.length !== 0) scrollContentCTA.click(this.content);
        },
        top: function() {
            $('html,body').animate({scrollTop: 0}, 750, 'easeInOutCubic');
        },
        content: function() {
            $('html,body').animate({scrollTop: $("#main-content").offset().top}, 500, 'easeInOutCubic');
        }
    };

	scrollTo.init();
}

Sandro.newspaper.responsiveLayout = function(){
	var responsiveLayout = {
	    init: function() {
		    this.controller();
		    $(window).resize(this.controller.bind(this));
	    },
	    controller: function() {
		    var winWid = window.innerWidth;
		    if (winWid > 768) {
			    this.toDesktop();
		    } else if (winWid < 768) {
			    this.toMobile();
		    }
	    },
	    state: 'desktop',
	    toDesktop: function() {
		    var firstSection = $('#article-main-content section').get(0);
		    var picture = $(firstSection).children('.cover');
		    if (this.state === 'mobile') {
			    this.state = 'desktop';
			    //Append picture at bottom of paragraph
			    $(firstSection).prepend(picture);
		    }
	    },
	    toMobile: function() {
		    var firstSection = $('#article-main-content section').get(0);
		    var picture = $(firstSection).children('.cover');
		    if (this.state === 'desktop') {
			    this.state = 'mobile';
			    //Append picture at bottom of paragraph
				$(firstSection).append(picture);
		    }
	    }
    };

	responsiveLayout.init();
}

Sandro.newspaper.pageLogoBlack = function(pageContext, isLogoBlack){
	if(pageContext == "creator"){
	 	if($('body').width() < 1024){
	 		isLogoBlack = true;
	 	}
	 }

	 if(typeof isLogoBlack !== "undefined" && isLogoBlack === false) {
	     $("#header, #navigation").addClass("light");
	     if($('.topMessage').length > 0) {
	         $('.topMessage').find('font').attr('color', 'white');
	     }
	 }
}

Sandro.newspaper.loadVimeoPlayer = function(){

	if ($(".iframe-video.withSplash").length > 0) {
		$(".iframe-video.withSplash").on('click', 'img', function() {
			$(this).parents(".iframe-video").find(".cover img").css("visibility", "hidden");
			var video_url = $(this).parents(".iframe-video").find("input#iframe").attr("data-url");
			var autoplay = $(this).parents(".iframe-video").find("input#iframe").attr("data-autoplay");
			var iframe = '<iframe src="' + video_url + '?autoplay=' + autoplay + '&rel=0" width=vimeoHeight height=vimeoWidth frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>';
			var playerInDom = $(this).parents(".iframe-video").append(iframe);
		});
	}
}

Sandro.newspaper.navigation = function () {
	/* Display the subcategory the creators, newspaper and hide the number of product not use in this case    */
	var navigationStories = $('div#navigation nav ul.level-1 li.stories-landing');
	navigationStories.find("span").empty();
}

Sandro.newspaper.selectArticle = function () {
	var totalTab = window.location.href.split('/').length;
	var url = window.location.href
		.split('/')
  		.slice(0,totalTab - 1).join('/');
	$("li.stories-landing ul.level-2 a").each(function(){
		var link = $(this).attr("href");
		if(link.indexOf(url) >= 0){
			$(this).css("font-weight", "bold");
		}
	});
}

Sandro.newspaper.callSlider = function() {
	function isRetina(){
	    return ((window.matchMedia && (window.matchMedia('only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)').matches)) || (window.devicePixelRatio && window.devicePixelRatio >= 2)) && /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
	}
	if($(".callSlider").length > 0){
		if($('body').width() >= 767){
			var device = "desktop";
			var nbView = 2;
		}else{
			var device = "mobile";
			var nbView = 1;
		}
		var url = $(".callSlider").data("url")+"&device="+device+"&retina="+isRetina();
		$.ajax({
			url: url
		}).done(function(data){
			$(".callSlider").html(data);
			var mySwiper = new Swiper('.defaultSlider', {
			    effect: 'coverflow',
		        grabCursor: true,
		        centeredSlides: true,
		        slidesPerView: nbView,
		        centeredSlides: true,
		        initialSlide: 4,
		        coverflow: {
		            rotate: 0,
		            stretch: 40,
		            depth: 250,
		            modifier: 2,
		            slideShadows : false
		        },
		        nextButton: '.swiper-button-next',
	        	prevButton: '.swiper-button-prev',
			});

			$(window).resize(function(){
			  var ww = window.innerWidth;
			  if (ww<=768) mySwiper.params.slidesPerView = 1;
			  if (ww>=768) mySwiper.params.slidesPerView = 2;
			});

		})
	}
}

Sandro.splash.events = function(){

    $(document).ready(function(){
        if ($('.splash-content-2021-js').length > 0) {
            $('body').addClass('splash-page-2021');
            $('.splash-content-2021-js').removeClass('splash-content-2021');
        };
    });


	var errorWrap = $('.newsletter-form-message');

	$('input[type="submit"]').on('click', function(e){
		if ( !$('input[type="email"]').val().length && !$('.gender a').hasClass('selected') ) {
			$('.gender a').addClass('error');
			errorWrap.html(app.resources.SPLASH_NL_GENDER).show();
		} else if ( !$('input[type="email"]').val().length ) {
			errorWrap.html(app.resources.SPLASH_NL_MAIL).show();
		} else if ( !$('.gender a').hasClass('selected') ) {
			$('.gender a').addClass('error');
			errorWrap.html(app.resources.SPLASH_NL_GENDER).show();
		}
	});

	$('.gender a').on('click', function(e){
		e.preventDefault();
		// Clear all class on click
		$('.gender a').removeClass('error');
		$('.gender a').removeClass('selected');
		// Adding selected on current one
		$(this).addClass('selected');
	});

	$('.country').on('click', function(e){
		$('.country-select').toggleClass('active');
		$("html, body").animate({ scrollTop: $(document).height() }, 1000);
	});

	if ( $('.country-select').hasClass('active') ) {
		if ( window.innerWidth > 1024 ) {
			$('.country-select').on('mouseover', function(){
				setTimeout(function(){
					$(this).removeClass('active');
				}, 500)
			})
		}
	}


	//Tracking Alphalyr
    $('.splashcontent .wrap-img').on('click', function(e) {
        var boutiqueValue = $(this).data("boutique");
        dataLayer.push(
            {
                'event':'click-boutique',
                'eventCategory':'storeHomepage',
                'eventAction':'Clic',
                'eventLabel': boutiqueValue
            }
        );
    });

	//Video
	var chapeauVideo = $(".chapeau-video");
    var btnSound = $(".btn-mute");
    var videoSound = false;
    var windowWidth = window.innerWidth;
    var isSmallScreen = false;

    if(chapeauVideo.length){

    	//Btn Mute
    	btnSound.on("click", function() {
			if( videoSound === false ){
				videoSound = true;
				btnSound.addClass("mute");
	            chapeauVideo.prop('muted', false);
	        } else if ( videoSound === true ){
	        	videoSound = false;
				btnSound.removeClass("mute");
	            chapeauVideo.prop('muted', true);
	        }
        });

    	//Stop video on small screen (mob/tablet)
        $(window).on('resize', function() {

        	windowWidth = window.innerWidth;

        	if(windowWidth < 1024 && isSmallScreen === false){
        		isSmallScreen = true;
	        	chapeauVideo[0].pause();
	        } else if (windowWidth >= 1024 && isSmallScreen === true){
	        	isSmallScreen = false;
	        	chapeauVideo[0].play();
	        }
        }).resize();
    }
}

// add contains function on String object if not already existing
if (!String.prototype.contains ) {
    String.prototype.contains = function() {
        return String.prototype.indexOf.apply( this, arguments ) !== -1;
    };
}

//show and hide filters
$(document).ready(function(){

	/* SMCP-3153 : Close QuickView when clicking on overlay */
	app.quickView && $('body').on('click', '.ui-widget-overlay', app.quickView.close);

/*
	$('.filter-filter-list').click(function(){

		$('.filter-order-by').find('li:not(.active)').hide();
		$('.filter-articles').find('li:not(.active)').hide();
		contF = $('.contentFilter');
		if( contF.is(":visible") )
			contF.slideUp();
		else
			contF.slideDown();
	});
	$('.filter-order-by').click(function(){

		$('.filter-articles').find('li:not(.active)').hide();
		$('.contentFilter').hide();
	});
	$('.filter-articles').click(function(){

		$('.contentFilter').hide();
		$('.filter-order-by').find('li:not(.active)').hide();
	});
*/
});

//Add the height for the separator menu

$('.menu-category.level-1 .menu-wrapper').each(function(){
	var menu = $(this);
	if(menu.find('.categoryTitle').length <= 0){
		menu.find('.level-2').each(function(){
			$(this).removeClass("offTitle");
		});
	}
});

Sandro.login.bindEvents = function(){
	var date  =  new Date();
	$('#button2').click(function(){
		var lp = $(this).parents('#dwfrm_login').find("input[name$=password]").val();
		var remembermeStatus = $(this).parents('#dwfrm_login').find("input[name$=rememberme]").attr("checked");
		if(remembermeStatus == 'checked'){
			date.setFullYear(9999,12,30);
			var cookieDate = date.toUTCString();
			document.cookie = "signPw="+lp+";expires= "+cookieDate+"";
		}else{
			document.cookie = "signPw=;expires=" + date.toGMTString();
		};
	});
};
Sandro.login.bindEvents();

/*
	Sandro Einstein
	Content Slot template : einstein_cartimpulse.isml
*/
Sandro.einstein.bindEvents = function(){
	Sandro.einstein.sliderCart();
	Sandro.product.eventLookComplet();
}

/*
	Init slider for Einstein
*/
Sandro.einstein.sliderCart = function() {
	/* init slider */
	if($('.einstein .swiper-container').length){
		/*	We need to init slider ever time .completeLook .swiper-container2 is present
			Look Complet also uses this slider in the Product Page 	*/
		$('.einstein .swiper-container').each(function( i ) {
			var ensteinSwiper = $(this).swiper({
				slidesPerView: 4,
				calculateHeight: true
			});
			/* button next and prev */
			$(".einstein .arrow-left").on("click", function(e) {
				e.preventDefault();
				ensteinSwiper.swipePrev();
			});
			$(".einstein .arrow-right").on("click", function(e) {
				e.preventDefault();
				ensteinSwiper.swipeNext();
			});
		});
	}
}

/*
This method is used in validator.js
This method exist and has the same name in Maje.js to make an unique call in validator.js
*/
Common.validator.refreshCartAmounts = function(zipCodeValue) {

	Sandro.cart.refreshCartAmounts(zipCodeValue);
}

Sandro.contact.bindEvents = function(event) {

	var contactQuestionSelect = $('.contact-question-select'),
		smartTribuneEnabled = app.constants.SMARTTRIBUNE_ENABLE,
		smartTribuneHtml = $('#st-faq'),
		smartTribunePlaformId = app.constants.SMARTTRIBUNE_PLATFROM_ID,
		siteLocale = smartTribuneHtml.attr('data-currentLocale');

	contactQuestionSelect.change(function(event) {
		$('body').addClass('subject-selected');

		var themeId = $(this).find('option:selected').attr('data-themeId');

		if (smartTribuneEnabled === true) {
			smartTribuneHtml.html('');
			if (themeId !== undefined) {
				new STCFAQ({
					container: '#st-faq',
					platformId: smartTribunePlaformId,
					theme_id: [themeId],
					locale: siteLocale,
					labels: {
						en: {
							intro		: "Frequently asked questions",
							noResult	: "There is no question",
							noAnswer	: "There is no answer"
						},
						de: {
							intro		: "Häufigen Fragen",
							noResult	: "Es gibt keine Frage",
							noAnswer	: "Es gibt keine Antwort"
						},
						fr: {
							intro		: "Questions fréquentes",
							noResult	: "Il n'y a pas de question",
							noAnswer	: "Il n'y a pas de réponse"
						},
						es: {
							intro		: "Preguntas frecuentes",
							noResult	: "No hay pregunta",
							noAnswer	: "No hay respuesta"
						},
						it: {
							intro		: "Domende frequenti",
							noResult	: "Non c'è domande",
							noAnswer	: "Non c'è risposta"
						}
					},
					limit: 5,
					disableStyle: true
				});
			}
		}
	});
}